import React from "react";
import {openDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";
import {getChat} from "../../../app/hooks";
import Table from "../../framework/table/Table";
import {formatDateTime} from "../../../providers/time";
import {image} from "../../../app/helpers";
import {renderPrice} from "../../../providers/price";
import Response from "./Response";


class Chat extends React.Component {

    state = {
        reload: false
    }

    reload = () => {
        this.setState({...this.state, reload: !this.state.reload});
    }

    renderInfo = (item) => {
        if(!item.product)       {
            return  <div className="font-weight-bold"> Proizvod je obrisan </div>;
        }
        return <div>
            <div className="mb-3">
                <div className="d-flex align-items-center">
                    <div className="mr-3" style={{width: 50}}>
                        <img src={image(item.product.image_path)} alt={item.product.name} className="img-fluid"/>
                    </div>
                    <div>
                        <div className="mb-2 font-weight-bold">
                            <a href="/" onClick={(e) => this.props.openDrawer({e: e, name: "product", id: item.product.id})}>
                                {item.product.name}
                            </a>
                        </div>
                        <div className="d-flex">
                            <div className="mr-3 bg-white">
                                {renderPrice(item.product)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-1">
                <a href="/" className="inherit" onClick={(e) => this.props.openDrawer({e: e, name: "supplier", id: item.product.supplier?.id})}>
                    Dobavljač: {item.product.supplier?.name}
                </a>
            </div>
        </div>
    }

    renderMessage = (item) => {
        return <div>
            <div className="font-weight-bold">Podaci korisnika:</div>
            {item.email_active &&
            <div className="mb-1"> Putem Email: {item.email} </div>}

            {item.notification_active &&
            <div className="mb-3"> Putem Notifikacija: {item.auth} </div>}

            <div className="font-weight-bold">Poruke:</div>
            <div className="p-2 bg-light">
                {item.message}
                <div className="font_09 text-black-50">
                    {formatDateTime(item.created_at)}
                </div>
            </div>

            {item.response?.length > 0 &&
                item.response.map((res) => {
                    return  <div key={res.id} className="alert alert-info mt-2 mb-0">
                        {res.message}
                        <div className="font_09 text-black-50">
                            {res.admin.name} {formatDateTime(res.created_at)}
                        </div>
                    </div>
            })}

            <div className="mt-3">
               <Response item={item} reload={this.reload} />
            </div>
        </div>
    }

    render = () => {
        return <div>
            <div className="container">
                <Table
                    columns={[
                        {field: "info", name: "Info", width: "40%", render: this.renderInfo},
                        {field: "message", name: "Poruka", render: this.renderMessage},

                    ]}
                    form={{withProduct: true, withResponse: true}}
                    hook={getChat}
                    reload={this.state.reload}
                />
            </div>
        </div>
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Chat);