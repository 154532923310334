export const SET_LIVE = "SET_LIVE";
export const DISCONNECT_LIVE = "DISCONNECT_LIVE";

export function setLive(value) {
    return {
        type: SET_LIVE,
        payload: value
    }
}
export function disconnectLive(value) {
    return {
        type: DISCONNECT_LIVE,
        payload: value
    }
}