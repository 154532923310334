import React from "react";
import Chart from "react-google-charts";
import moment from "moment";


class ProductHistory extends React.Component {

    state = {
        dates: [],
        history: {}
    }

    render = () => {
        let holder = [[
            { type: 'date', label: 'Day' },
            'Izračunata Cena',
            'Sistemska Cena',
            'Kupovna Cena',
        ]];
        let price = 0;
        let sistem = 0;
        let purchase = 0;
        for(let i=0; i<this.state.dates.length; i++)        {
            const date = this.state.dates[i];
            const datesArr = date.split(" ");
            if(this.state.history[date])        {
                price = this.state.history[date].calculated_price;
                sistem = this.state.history[date].recommended_price;
                purchase = this.state.history[date].purchase_price;
            }
            holder.push([new Date(parseInt(datesArr[0]), parseInt(datesArr[1]-1), parseInt(datesArr[2])),
                parseInt(price), parseInt(sistem), parseInt(purchase)])
        }
        return <Chart
            width={'100%'}
            height={200}
            chartType="Line"
            loader={<div>Loading Chart</div>}
            data={holder}
            options={{
                chart: {
                    title:
                        'Cene (zadnjih mesec dana)',
                },
                width: "100%",
                height: 200,
            }}
            rootProps={{ 'data-testid': '4' }}
        />
    };

    componentDidMount() {
        const dates = [];
        const history = {};
        for(let i=0; i<this.props.items.length; i++)        {
            const item = this.props.items[i];
            history[moment.utc(item.created_at).format("YYYY M D")] = item;
        }
        for(let i=0; i<30; i++)     {
            dates.push(moment().subtract(i, "days").format("YYYY M D"));
        }
        this.setState({...this.state, dates: dates.reverse(), history: history});
    }
}
export default ProductHistory;
