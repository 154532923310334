import React from "react";
import {getCategories, patchCategoriesOrder} from "../../../app/hooks";
import Nestable from "react-nestable"
import CategoryItem from "./CategoryItem";
import AddCategory from "./AddCategory";
import {Link} from "react-router-dom";
import routes from "../../../app/routes";
import {closeDrawer, openDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";

class Categories extends React.Component {

    state = {
        categories: [],
        form: {
            all: true,
            withChildren: true,
            search: ""
        }
    }

    changedOrder = (items) => {
        patchCategoriesOrder({items: items}).then((response) => {
            this.load();
        });
    }

    collapseIcons = ({ isCollapsed }) => {
        if(isCollapsed) return <span className="nestable_collapse_icon"><i className="fa fa-plus" /></span>
        return <span className="nestable_collapse_icon"><i className="fa fa-minus" /></span>;
    };

    search = (e) => {
        const newState = {...this.state, form: {...this.state.form, search: e.target.value}};
        this.setState(newState);
        this.load(newState.form);
    }

    render = () => {
        return <div className="container">
            <div className="mb-3 d-flex align-items-center justify-content-between">
                <div className="d-flex flex-grow-1">
                    <input  className={this.state.form.search ? "form-control border-primary border_3" : "form-control"}
                            name="search" type="text" autoComplete="off" placeholder="Pretraga..."
                            onChange={this.search} value={this.state.form.search}
                    style={{width: 200}}/>

                    <Link to={routes.mappedCategories.route} className="btn btn-primary ml-2">
                        {routes.mappedCategories.name}
                    </Link>

                    <button onClick={() => this.props.openDrawer({name: "productsTransfer"})}
                            className="btn btn-primary ml-2">
                        Prebacivanje proizvoda
                    </button>
                </div>
                <div className="text-right">
                   <AddCategory reload={this.load} />
                </div>
            </div>
            <Nestable
                collapsed={true}
                maxDepth={3}
                onChange={this.changedOrder}
                renderCollapseIcon={this.collapseIcons}
                handler={<span className="nestable_collapse_icon"><i className="fa fa-arrows" /></span>}
                items={this.state.categories}
                renderItem={(props) => <CategoryItem  {...props} reload={this.load}  />}
            />
        </div>
    };

    load = (form) => {
        if(!form) form = this.state.form;
        getCategories(form).then((response) => {
            this.setState({...this.state, categories: response.data});
        })
    }

    componentDidMount() {
      this.load(this.state.form);
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Categories);
