import React from 'react';
import  { Redirect } from 'react-router-dom';
import {connect} from "react-redux";
import LeftMenu from "./LeftMenu";
import Drawer from "../framework/Drawer";
import {closeDrawer} from "../../store/actions/drawersActions";
import ProductDrawer from "../drawers/product/ProductDrawer";
import Success from "../drawers/Success";
import SupplierDrawer from "../drawers/SupplierDrawer";
import Categories from "../pages/categories/Categories";
import Products from "../pages/products/Products";
import Error from "../drawers/Error";
import routes from "../../app/routes";
import {logoutAdmin} from "../../providers/adminProvider";
import OrderDrawer from "../drawers/OrderDrawer";
import FilesDrawer from "../drawers/FilesDrawer";
import Socket from "../framework/Socket";
import MailerDrawer from "../drawers/MailerDrawer";
import ProductsTransferDrawer from "../drawers/ProductsTransferDrawer";
import {setReloader} from "../../store/actions/reloadersActions";


class Layout extends React.Component {


    logout = (e) => {
        e.preventDefault();
        logoutAdmin(true);
    }

    render() {
        if(!this.props.admin) return <Redirect to={routes.login.route}  />
        return (
            <>
            <Socket />

            <Drawer
                width="80%"
                reload={true}
                show={this.props.drawers.products.status}
                close={() => this.props.closeDrawer("products")}>

                <div className="m-4">
                    <Products />
                </div>
            </Drawer>

                <Drawer
                    width="80%"
                    reload={true}
                    show={this.props.drawers.product.status}
                    close={() => this.props.closeDrawer("product")}>

                    <ProductDrawer />
                </Drawer>

                <Drawer
                    width="80%"
                    reload={true}
                    show={this.props.drawers.files.status}
                    close={() => this.props.closeDrawer("files")}>

                    <FilesDrawer />
                </Drawer>

                <Drawer
                    width="90%"
                    reload={true}
                    show={this.props.drawers.productsTransfer.status}
                    close={() => this.props.closeDrawer("productsTransfer")}>

                    <ProductsTransferDrawer />
                </Drawer>

            <Drawer
                width="80%"
                reload={true}
                show={this.props.drawers.supplier.status}
                close={() => this.props.closeDrawer("supplier")}>

                <SupplierDrawer />
            </Drawer>

            <Drawer
                width="80%"
                show={this.props.drawers.categories.status}
                close={() => this.props.closeDrawer("categories")}>

                <div className="m-3">
                    <Categories />
                </div>
            </Drawer>

            <Drawer
                width="80%"
                reload={true}
                show={this.props.drawers.order.status}
                close={() => this.props.closeDrawer("order")}>

                <OrderDrawer />
            </Drawer>

            <Drawer
                width="40%"
                zIndex={100}
                reload={true}
                show={this.props.drawers.mailer.status}
                close={() => this.props.closeDrawer("mailer")}>

                <MailerDrawer />
            </Drawer>

            <Success />
            <Error />


            <div className="row no-gutters">

                <LeftMenu />

                <div className="col">
                    <div className="text-right container-fluid mb-3 mt-1">
                        <span className="mr-3 text-black-50">
                            {this.props.admin?.email}
                        </span>
                        <button onClick={this.logout}
                            title="IZLOGUJTE SE" className="btn btn-white bg-white btn_round shadow border-light mr-2">
                            <i className="fas fa-sign-out-alt" />
                        </button>
                        <button
                            title="IZLOGUJTE SE" className="btn btn-white bg-white btn_round shadow border-light">
                            <i className="far fa-bell" />
                        </button>
                    </div>
                    <div className="container-fluid mb-5">
                        {this.props.children}
                    </div>
                </div>


            </div>
        </>
        );
    }

    pool = () => {
        setInterval(() => {
            this.props.setReloader("unseenOrders");
            this.props.setReloader("unseenChat");
        }, 10000);
    }


    componentDidMount() {
        this.pool();
    }
}
const mapStateToProps = state => ({
    drawers: state.drawers,
    admin: state.admin
});
const mapDispatchToProps = {
    closeDrawer: closeDrawer,
    setReloader: setReloader
};
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
