import React from "react";
import {connect} from "react-redux";
import {closeDrawer} from "../../store/actions/drawersActions";


class Success extends React.Component {

    render = () => {
        return <>
            <div className={this.props.drawer.status ? "success_alert alert_open" : "success_alert"}>
                {this.props.drawer.content || "Sačuvano!"}
            </div>
            </>
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer.status)        {
            setTimeout(() => {
                this.props.closeDrawer("success");
            }, 2000);
        }
    }

}
const mapStateToProps = state => ({
    drawer: state.drawers.success
});
const mapDispatchToProps = {
    closeDrawer: closeDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Success);
