import React from 'react';
import {connect} from "react-redux";
import Loader from "../../framework/Loader";
import {deleteProduct, getProducts, patchPath, putProductImages} from "../../../app/hooks";
import Checkbox from "../../framework/form/Checkbox";
import Input from "../../framework/form/Input";
import {formatPrice} from "../../../providers/price";
import Empty from "../../framework/Empty";
import Textarea from "../../framework/form/Textarea";
import {closeDrawer, openDrawer} from "../../../store/actions/drawersActions";
import ProductHistory from "./ProductHistory";
import DateTimePicker from "../../framework/form/DateTimePicker";
import ProductGallery from "./ProductGallery";
import ProductNew from "./ProductNew";
import {image} from "../../../app/helpers";


class ProductDrawer extends React.Component {

    state = {
        product: false,
        selected: [],
        new: false
    }

    changeCategory = (cat) => {
        if(!cat) return false;
        patchPath({path: `products.${this.state.product.id}.category_id`, value: cat.id}).then((response) => {
            this.load();
        });
    }

    addImages = (images) => {
        if(images.length === 0) return false;
        const ids = images.map(item => item.id);
       putProductImages({images: ids, product_id: this.state.product.id}).then(response => {
           this.load();
       });
    }

    hardDelete = () => {
        if(!window.confirm("Zelite da obrišete proizvod? Proizvod će biti ponovi ažuriran."))  {
            return false;
        }
        deleteProduct({id: this.state.product.id}).then((response) => {
            this.props.closeDrawer("product");
        });
    }

    softDelete = (val) => {
        patchPath({path: `products.${this.state.product.id}.deleted`, value: val}).then((response) => {
            this.load();
        });
    }

    render() {
        if(this.state.new) {
            return <ProductNew reload={this.load} />
        }
        if(!this.state.product)     {
            return <Loader />
        }
        const product = this.state.product;
        return (
            <div>
                <h4 className="p-2">{product.name}</h4>

                {product.deleted &&
                <div className="alert alert-danger">
                    Proizvod je obrisan. Možete ga vratiti na stanje klikom na dugme
                    <button
                        onClick={() => this.softDelete(null)}
                        className="btn btn-danger ml-3">VRATITE</button>
                </div>}

                <div className="p-3">
                    <div className="bg-white shadow mb-3">
                        <div className="m-3">
                            <ProductHistory items={product.history} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">

                            <div className="bg-white shadow mb-3 p-3">
                                <div className="row">
                                    <div className="col-md-4">
                                        <Checkbox
                                            disabled={true}
                                            title="Stanje proizoda postavljen azuriranjem (samo sistem moze da menja)."
                                            checked={!!product.state}
                                            name="state"
                                            true={<span className="text-success font-weight-bold">Na stanju</span>}
                                            false={<span className="text-danger font-weight-bold">Nema na stanju</span>}/>
                                    </div>
                                    <div className="col-md-4">
                                        <Checkbox
                                            title="Deaktivirani proizvodi se ne prikazuju kupcima."
                                            checked={!!product.active}
                                            path={`products.${product.id}.active`}
                                            name="active"
                                            true={<span className="text-success font-weight-bold">Aktiviran</span>}
                                            false={<span className="text-danger font-weight-bold">Deaktiviran</span>}/>
                                    </div>
                                    <div className="col-md-4">
                                        <Checkbox
                                            title="Rasprodati proizvodi se prikazuje ali ne moze da se poruci."
                                            checked={!!product.supply}
                                            path={`products.${product.id}.supply`}
                                            name="supply"
                                            true={<span className="text-success font-weight-bold">Nije rasprodato</span>}
                                            false={<span className="text-danger font-weight-bold">Rasprodato</span>}/>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white shadow p-3 mb-3">
                                {product.category?.name}
                                <button  onClick={() => this.props.openDrawer({name: "categories",
                                    callback: this.changeCategory})}
                                    className="btn btn-white ml-3">
                                    Promeni
                                </button>
                            </div>

                            <div className="bg-white shadow p-3 mb-3">
                                <div className="mb-2">
                                    <Input
                                        submit={true}
                                        title={<div className="font-weight-bold"> Ime </div>}
                                        path={`products.${product.id}.name`}
                                        value={product.name} name="name"
                                        maxLength={191} autoComplete="off"  />
                                </div>
                                <div className="mb-2 p-0">
                                    <Input
                                        submit={true}
                                        title={<div className="font-weight-bold"> Model </div>}
                                        path={`products.${product.id}.model`}
                                        value={product.model} name="model"
                                        maxLength={191} autoComplete="off"  />
                                </div>
                                <div className="row mb-2">
                                    <div className="mb-2 col-md-6">
                                        <Input
                                            submit={true}
                                            title={<div className="font-weight-bold"> Barkod </div>}
                                            path={`products.${product.id}.ean`}
                                            value={product.ean} name="ean"
                                            maxLength={191} autoComplete="off"  />
                                    </div>
                                    <div className="mb-2 col-md-6">
                                        <Input
                                            submit={true}
                                            title={<div className="font-weight-bold"> Brend </div>}
                                            path={`products.${product.id}.brand`}
                                            value={product.brand} name="brand"
                                            maxLength={191} autoComplete="off"  />
                                    </div>
                                </div>

                                <div>
                                    <Textarea
                                        submit={true}
                                        title={<div className="font-weight-bold"> Opis </div>}
                                        path={`products.${product.id}.description`}
                                        value={product.description} name="description"
                                        />
                                </div>
                            </div>

                            <div className="bg-white shadow p-3">
                                <div className="font-italic red mb-2">
                                    *Mnogi proizvodi koriste iste nazive specifikacije. <br/> Promena na ovom proizvodu
                                    će verovatno uticati i na ostale.
                                </div>
                                {product.filters.map((item) => {
                                    return <div key={item.id} className="d-flex align-items-center mb-2">
                                        <div className="mr-2 flex-grow-1">
                                            <Textarea
                                                rows={1}
                                                submit={true}
                                                path={`filters.${item.parent_id}.name`}
                                                value={item.parent_name} />
                                        </div>
                                        <div className="flex-grow-1">
                                            <Textarea
                                                rows={1}
                                                submit={true}
                                                path={`filters.${item.id}.name`}
                                                value={item.name} />
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="mb-3 bg-white shadow p-3">
                                Dobavljač: <h5 className="d-inline-block mb-0"> {product.supplier?.name} </h5>
                                <div> Šifra: <strong> {product.code} </strong></div>
                                ID: <strong> {product.id} </strong>
                            </div>

                            <div className="bg-white shadow p-3 mb-3">
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <div title="Cena od dobavljaca (u fajlu, sistem azurira).">
                                            Kupovna cena: <strong> {formatPrice(product.purchase_price)} </strong>
                                        </div>
                                        <div title={`PDV na kupovnu cenu.`}>
                                            Pdv: {(product.pdv === 0 ? "0" : product.pdv) || <span className="text-danger">prazno</span>}
                                        </div>
                                        <div title={`MARZA za kupovnu cenu."`}>
                                            Marža: {(product.margo === 0 ? "0" : product.margo) || <span className="text-danger">prazno</span>}
                                        </div>
                                        <div>
                                            Izračunata: <strong> {formatPrice(product.calculated_price)} </strong>
                                        </div>
                                        <div>
                                            Zalihe:  {product.stock}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div>
                                            Min marža: {product.min_margo || <Empty />}
                                        </div>
                                        <div>
                                            {product.min_margo && product.pdv &&
                                            <div title="Minimum cena ispod koje se ne ide: kupovna cena x pdv + min marža)">
                                                Min cena: {formatPrice((product.purchase_price * (1 + parseInt(product.pdv) / 100)) + parseFloat(product.min_margo))}
                                            </div>}
                                        </div>
                                        <div>
                                            Sistemska cena: {formatPrice(product.recommended_price) || <Empty/>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row bg-light p-2">
                                    <div className="col-md-6 mb-3">
                                        <strong> Admin cena: </strong>
                                        <Input
                                            nullable={true}
                                            path={`products.${product.id}.admin_price`}
                                            value={product ? product.admin_price : ""}
                                            submit={true}
                                            name="admin_price"
                                            price={true} />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <strong> Cena pre popusta: </strong>
                                        <Input
                                            nullable={true}
                                            path={`products.${product.id}.admin_discount_price`}
                                            value={product ? product.admin_discount_price : ""}
                                            submit={true}
                                            name="admin_discount_price"
                                            price={true} />
                                    </div>

                                    <div className="col-md-6">
                                        <div className="font-weight-bold">SNIŽENJE POČINJE:</div>
                                        <DateTimePicker
                                            path={`products.${product.id}.countdown_start`}
                                            value={product.countdown_start}
                                            name={`dateTimePicker_single_${product.id}_start`} />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="font-weight-bold">SNIŽENJE SE ZAVRŠAVA:</div>
                                        <DateTimePicker
                                            path={`products.${product.id}.countdown_end`}
                                            value={product.countdown_end}
                                            name={`dateTimePicker_single_${product.id}_end`} />
                                    </div>
                                </div>
                            </div>


                            <div className="mb-3">
                                <button className="btn btn-primary"
                                    onClick={() => this.props.openDrawer({name: "files", bucket: "goproducts",
                                    callback: this.addImages})}>
                                    Dodajte slike
                                </button>
                            </div>
                            <div className="row mb-3 no-gutters">
                                <ProductGallery product={product} reload={this.load} />
                            </div>

                            <div className="bg-white shadow p-3 mb-3">
                                <button
                                    onClick={this.hardDelete}
                                    className="btn btn-white mr-2">
                                    Obrišite (ponovo ažuriraj)
                                </button>
                                <button
                                    onClick={() => this.softDelete(product.deleted ? null : 1)}
                                    className="btn btn-danger">
                                    {product.deleted ? "VRATITE NA STANJE" : "Obrišite (bez ažuriranja)"}
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    load = (id) => {
        if(!id && !this.props.drawer.id)   {
            this.setState({...this.state, new: true});
            return false;
        }
        if(!id) {
            id = this.props.drawer.id;
        }
        getProducts({id: id, withImages: true, withDeleted: true, withHistory: true,
            withFilters: true, withSupplier: true, withCategory: true}).then((response) => {
            this.setState({...this.state, product: response.data, new: false});
        })
    }

    componentDidMount() {
        this.load();
    }
}
const mapStateToProps = state => ({
    drawer: state.drawers.product
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductDrawer);
