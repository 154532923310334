import React from "react";
import {patchOrder} from "../../../app/hooks";
import {order_status, order_statuses} from "../../../providers/order";


class OrderStatusChange extends React.Component {

    state = {}

    changeStatus = (e) => {
        const status = e.target.value;
        var r = window.confirm("Želite da promenite status?");
        if (r !== true) {
            return false;
        }
        patchOrder({id: this.props.order.id, status: status}).then((response) => {
            if (this.props.reload) {
                this.props.reload();
            }
        });
    }

    render = () => {
        const order = this.props.order;
        const status = order_status(order.status);
        return <div className="p-1" style={{background: status.color}}>
            <div className="font-weight-bold text-white font_09"> Promenite status:</div>

            <select className="form-control form-control-sm" value={order.status} onChange={this.changeStatus}>
                {Object.values(order_statuses()).map((item, i) => {
                    return <option value={item.id} key={item.title}>
                        {item.title}
                    </option>
                })}
            </select>
        </div>
    };

}

export default OrderStatusChange;
