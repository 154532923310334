import React from "react";
import {image} from "../../app/helpers";
import Input from "../framework/form/Input";
import Checkbox from "../framework/form/Checkbox";
import {openDrawer} from "../../store/actions/drawersActions";
import {connect} from "react-redux";
import {deleteBanners, patchPath} from "../../app/hooks";


class Banner extends React.Component {

    state = {
        item: this.props.item,
        settings: false
    };

    update = (field,value) => {
        patchPath({path: `banners.${this.props.item.id}.${field}`, value: value}).then((response) => {
            if(this.props.reload)   {
                this.props.reload();
            }
        })
    };

    delete = () => {
        if (!window.confirm("Želite da obrišete slajd?")) {
            return false;
        }
        deleteBanners({id: this.props.item.id}).then((response) => {
            if(this.props.reload)   {
                this.props.reload();
            }
        });
    };

    render = () => {
        let item = this.state.item;
        return <div className="border mb-2 bg-light">

            <div className="d-flex align-items-center justify-content-between p-1">
                <div>
                    {this.props.handler} &nbsp;
                    <img src={image(this.props.item.image_path, false, this.props.item.image_bucket)} width="80px" alt="slider" />
                </div>
                <div>
                    <button className="mr-2 btn btn-secondary btn-sm"
                            onClick={() => this.setState({...this.state, settings: !this.state.settings})}>
                        {this.state.settings ? "Zatvorite" : "Podešavanja"}
                    </button>
                    {this.props.delete !== false &&
                    <button className="btn btn-danger btn-sm" onClick={this.delete}>
                        Obriši
                    </button>}
                </div>
            </div>

            {this.state.settings &&
            <div className="p-2">
                <div className="row">
                    <div className="col-md-4">
                        <div className="row mb-3">
                            <div className="col">
                                <span className="font-weight-bold mb-2"> Desktop </span>
                                <div className="round overflow-hidden">
                                    <button onClick={() => this.props.openDrawer({name: "files", bucket: "gointerface",
                                        callback: (images) => this.update("image_id", images[0].id)})}>
                                        <img src={image(item.image_path, false, item.image_bucket)} className="img-fluid" alt={item.image_name} />
                                    </button>
                                </div>
                            </div>
                            <div className="col">
                                <span className="font-weight-bold mb-2"> Mobilni (760px) </span>
                                <div className="round overflow-hidden">
                                    <button onClick={() => this.props.openDrawer({name: "files", bucket: "gointerface",
                                        callback: (images) => this.update("mobile_image_id", images[0].id)})}>
                                        <img src={image(item.mobile_image_path, false, item.image_bucket)} className="img-fluid" alt={item.mobile_image_name ? item.name : "image not set"} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="mb-2">
                            <Input
                                placeholder="Unesite ALT (opis) slike."
                                className="w-100"
                                submit={true}
                                nullable={true}
                                name="alt"
                                path={`banners.${item.id}.alt`}
                                value={item.alt ? item.alt : ""}
                            />
                        </div>
                        <div className="mb-3">
                            <label>
                                <Checkbox
                                    checked={!!item.show_mobile}
                                    path={`banners.${item.id}.show_mobile`}
                                    value="1" />
                                <span className="font-weight-bold">Prikazuj na mobilnom</span>
                            </label>
                        </div>

                        <div className="mb-2">
                            <Input
                                placeholder="Link banera."
                                className="w-100"
                                submit={true}
                                nullable={true}
                                name="link"
                                modifier={(val) => {
                                    if(val && val.startsWith("https://gogorila.com"))        {
                                        val = val.replace("https://gogorila.com", "");
                                    }
                                    return val;
                                }}
                                path={`banners.${item.id}.link`}
                                value={item.link ? item.link : ""}
                            />
                        </div>
                        <div>
                            <label>
                                <Checkbox
                                    checked={!!item.blank}
                                    path={`banners.${item.id}.blank`}
                                    value="1" />
                                <span className="font-weight-bold"> Otvaraj u novom prozoru</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    };
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.item !== prevProps.item)      {
            this.setState({...this.state, item: this.props.item});
        }
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    openDrawer: openDrawer
};
export default  connect(mapStateToProps, mapDispatchToProps)(Banner);
