
var routes = {
    login: {route: "/cms/login", name: "Ulogujte se"},

    dashboard: {route: "/", name: "Dashboard"},
    products: {route: "/cms/proizvodi", name: "Proizvodi"},
    orders: {route: "/cms/porudzbine", name: "Porudžbine"},
    suppliers: {route: "/cms/dobavljaci", name: "Dobavljači"},
    categories: {route: "/cms/kategorije", name: "Kategorije"},
    mappedCategories: {route: "/cms/kategorije/mapirane", name: "Mapiranje kategorija"},
    filters: {route: "/cms/filteri", name: "Filteri"},
    welcome: {route: "/cms/naslovna", name: "Naslovna strana"},
    chat: {route: "/chat", name: "Pitanja"},
    live: {route: "/cms/live", name: "LIVE"},
}
export default routes;