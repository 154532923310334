import React from "react";
import {openDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";
import {getProducts} from "../../../app/hooks";
import Input from "../../framework/form/Input";
import {image} from "../../../app/helpers";
import Empty from "../../framework/Empty";
import {formatPrice} from "../../../providers/price";
import Checkbox from "../../framework/form/Checkbox";


class ProductMessages extends React.Component {

    state = {
        products: [],
        form: {
            limit: 200,
            withMessage: true,
            withSupplier: true
        }
    }

    render = () => {
        if(this.state.products.length === 0)    {
            return "";
        }
        return <div className="shadow bg-white">
            <h5 className="p-3 border-bottom">Poruke</h5>
            <div>
                {this.state.products.map((item) => {
                    return <div key={item.id} className="d-flex align-items-center border-bottom p-2">
                        <div className="mr-3" style={{width: 80}}>
                            <img src={image(item.image_path)} alt={item.name} className="img-fluid"/>
                        </div>
                        <div className="flex-grow-1">
                            <div className="alert alert-danger"> {item.message} </div>
                            <div>
                                <a href="/" onClick={(e) => this.props.openDrawer({e: e, name: "product", id: item.id})}>
                                    {item.name}
                                </a>

                                <div>Kategorija: {item.category?.name}</div>
                                <div>Brend: <Empty value={item.brand}/></div>
                                <div>Dobavljač:
                                    <a href="/" onClick={(e) => this.props.openDrawer({e: e, name: "supplier", id: item.supplier?.id})} className="ml-2">
                                        <b>{item.supplier?.name} </b>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Checkbox
                                title="Kliknite da uklonite."
                                checked={!!item.message}
                                path={`products.${item.id}.message`}
                                name="message"
                                onChange={() => this.load()}
                                true={<span className="text-success">U redu</span>}
                                false={<span className="text-danger">U redu</span>}/>
                        </div>
                    </div>
                })}
            </div>
        </div>
    }

    load = (form) => {
        if(!form) form = this.state.form;
        getProducts(form).then((response) => {
            this.setState({...this.state, products: response.data.data});
        })
    }
    componentDidMount() {
        this.load();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.reload && this.props.reload !== prevProps.reload)      {
            this.load();
        }
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductMessages);