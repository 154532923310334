import noImage from "./../assets/images/000-no-image.jpg";


export function server(path) {
    let gateway = `https://server.gogorila.com/${path}`;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        gateway = `http://127.0.0.1:5000/${path}`;
    }
    return gateway;
}
export function updater(path) {
    let gateway = `https://updater.gogorila.com/${path}`;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        gateway = `http://localhost:8001/${path}`;
    }
    return gateway;
}
export function serverIO() {
    let gateway = `https://server-io.gogorila.com/`;
    //let gateway = `http://134.122.78.245:8000/`;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        gateway = "http://127.0.0.1:8000/";
    }
    return gateway;
}

export function randString(length=5) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function image(name, big=false, bucket="goproducts")    {
    if(!name) return noImage;
    let folder = big ? 'lg' : 'sm';
    return  `https://storage.gogorila.com/${bucket}/${folder}/${name}`;
}
export function eimage(name)    {
    if(!name) return noImage;
    return  `https://storage.gogorila.com/eponuda/${name}`;
}

export function createQueryString(form) {
    let params = [];
    for(let key in form) {
        let value = form[key];
        if(!value) continue;
        if(typeof value === "object")    {
            value = JSON.stringify(value);
        }
        if(Array.isArray(value))    {
            value = JSON.stringify(value);
        }
        params.push(`${key}=${value}`);
    }
    if(params.length === 0) {
        return "";
    }
    return "?" + params.join("&");
}