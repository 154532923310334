import React from "react";
import {openDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";
import {getProducts, patchProductsActivationAll} from "../../../app/hooks";
import {image} from "../../../app/helpers";
import Empty from "../../framework/Empty";
import {formatPrice} from "../../../providers/price";
import Checkbox from "../../framework/form/Checkbox";


class DeactivatedProducts extends React.Component {

    state = {
        products: {
            data: []
        },
        form: {
            limit: 200,
            state: "no_active",
            withCategory: true,
            withSupplier: true
        }
    }

    activateAll = () => {
        if(!window.confirm("Zelite da aktivirate sve proizvode?"))  {
            return false;
        }
        patchProductsActivationAll({active: 1}).then((response) => {
           this.load();
        });
    }

    render = () => {
        if(this.state.products?.data.length === 0)    {
            return "";
        }
        return <div className="shadow bg-white">
            <h5 className="p-3 border-bottom d-flex justify-content-between align-items-center">
                Deaktivirani proizvodi ({this.state.products.total})
                <button
                    onClick={this.activateAll}
                    className="btn btn-white btn-sm">Aktiviraj sve</button>
            </h5>
            <div>
                {this.state.products.data.map((item) => {
                    const pdv = (item.pdv === 0) ? "0" : item.pdv;
                    const margo = (item.margo === 0) ? "0" : item.margo;
                    return <div key={item.id} className="d-flex align-items-center border-bottom p-2">
                        <div className="mr-3" style={{width: 50}}>
                            <img src={image(item.image_path)} alt={item.name} className="img-fluid"/>
                        </div>
                        <div className="flex-grow-1">
                            <div>
                                <a href="/" onClick={(e) => this.props.openDrawer({e: e, name: "product", id: item.id})}>
                                    {item.name}
                                </a>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    <div>Kategorija: {item.category?.name}</div>
                                    <div>Brend: <Empty value={item.brand}/></div>
                                    <div>Dobavljač:
                                        <a href="/" onClick={(e) => this.props.openDrawer({e: e, name: "supplier", id: item.supplier?.id})} className="ml-2">
                                            <b>{item.supplier?.name} </b>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div>Kupovna cena: {formatPrice(item.purchase_price)}</div>
                                    <div>
                                        <span className="mr-3">
                                            Pdv: {pdv || <span className="text-danger">prazno</span>}
                                        </span>
                                        <span>Marža: {margo || <span className="text-danger">prazno</span>}</span>
                                    </div>
                                    <div>Izračunata cena: {formatPrice(item.calculated_price)}</div>
                                </div>
                                <div className="" style={{width: 160}}>
                                    <Checkbox
                                        title="Deaktivirani proizvodi se ne prikazuju kupcima."
                                        checked={!!item.active}
                                        path={`products.${item.id}.active`}
                                        name="active"
                                        onChange={() => this.load()}
                                        true={<span className="text-success">Aktiviran</span>}
                                        false={<span className="text-danger">Deaktiviran</span>}/>
                                    <div>
                                        <Checkbox
                                            title="Rasprodati proizvodi se prikazuje ali ne moze da se poruci."
                                            checked={!!item.supply}
                                            path={`products.${item.id}.supply`}
                                            name="supply"
                                            true={<span className="text-success">Nije rasprodato</span>}
                                            false={<span className="text-danger">Rasprodato</span>}/>
                                    </div>
                                    zalihe: {item.stock}
                                </div>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    }

    load = (form) => {
        if(!form) form = this.state.form;
        getProducts(form).then((response) => {
            this.setState({...this.state, products: response.data});
        })
    }
    componentDidMount() {
        this.load();
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(DeactivatedProducts);