import React from "react";
import {connect} from "react-redux";
import {getBanners} from "../../../app/hooks";
import Loader from "../../framework/Loader";
import Banners from "../../elements/Banners";
import Banner from "../../elements/Banner";
import Blocks from "./Blocks";


class Welcome extends React.Component {

    state = {
        banners: false
    }

    render = () => {
        if(!this.state.banners)  {
            return <Loader />
        }
        return <div>

            <div className="bg-white shadow mb-4">
                <div className="p-2 border-bottom font-weight-bold font_2">Veliki slajder</div>
                <Banners
                    open={true}
                    name="indexslider"
                    reload={this.load}
                    banners={this.state.banners?.indexslider} />
            </div>

            <div className="bg-white shadow mb-5">
                <div className="p-2 border-bottom font-weight-bold font_2">Baneri pored slajdera</div>

                <div className="p-2">
                    <Banner
                        item={this.state.banners?.index1[0]}
                        reload={this.load}
                        delete={false} />
                    <Banner
                        item={this.state.banners?.index2[0]}
                        reload={this.load}
                        delete={false} />
                </div>
            </div>

            <Blocks />
        </div>
    }

    load = () => {
        getBanners().then((response) => {
            let group = response.data.reduce((r, a) => {
                r[a.name] = [...r[a.name] || [], a];
                return r;
            }, {});
            this.setState({...this.state, banners: group});
        });
    }

    componentDidMount() {
        this.load();
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Welcome);