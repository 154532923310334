import React from 'react';
import {connect} from "react-redux";
import Loader from "../framework/Loader";
import {getOrders, patchPath, putOrderHistory} from "../../app/hooks";
import {setReloader} from "../../store/actions/reloadersActions";
import {closeDrawer, openDrawer} from "../../store/actions/drawersActions";
import {order_status, payment_method} from "../../providers/order";
import {formatDateTime, formatTime} from "../../providers/time";
import OrderNote from "../pages/orders/OrderNote";
import {formatPrice} from "../../providers/price";
import {image} from "../../app/helpers";
import OrderStatusChange from "../pages/orders/OrderStatusChange";
import Transaction from "./Transaction";


class OrderDrawer extends React.Component {

    state = {
        order: false,
    }

    reload = () => {
         this.load();
            if(this.props.drawer.reload) {
                this.props.drawer.reload();
            }
    }

    callback = (message) => {
        putOrderHistory({id: this.props.drawer.id, note: "Email sent"}).then((response) => {
            this.reload();
        });
    }

    render() {
        if(!this.state.order)     {
            return <Loader />
        }
        const order = this.state.order;
        let emailMessage = "";
        let emailSubject = "";
        if(order.status === 2)      {
            emailMessage = "Vaša porudžbina je primljena.";
            emailSubject = "GoGorila - Porudžbina #"+ order.code +" je primljena"
        }
        if(order.status === 3)      {
            emailMessage = "Vaša porudžbina je poslata."
            emailSubject = "GoGorila - Porudžbina #"+ order.code +" je poslata"
        }
        if(order.status === 5)      {
            emailMessage = "Vaša porudžbina je otkazana."
            emailSubject = "GoGorila - Porudžbina #"+ order.code +" je otkazana"
        }
        if(order.status === 6)      {
            emailMessage = "Vaša porudžbina je na reklamaciji."
            emailSubject = "GoGorila - Reklamacija"
        }
        return (
            <div className="p-3">
                <div className="text-center mb-4 font_6"> Porudžbina #{order.code} </div>

                <div className="bg-white shadow p-2 mb-4">
                    <div className="row mb-3">
                        <div className="col-md-4 col-6">
                            <OrderStatusChange reload={this.reload} order={order} />
                        </div>
                        <div className="col-md-2"> </div>

                        <div className="text-right col-6">
                            <button onClick={() => this.props.openDrawer({name: "mailer", to: order.email, subject: emailSubject, body: emailMessage, callback: this.callback})}
                                className="btn btn-primary mr-2">
                                <i className="fa fa-envelope mr-2" /> Pošaljite EMAIL
                            </button>

                            <button onClick={() => this.props.openDrawer({
                                name: "mailer", to:
                                order.email,
                                subject: "GoGorila - Porudžbina #"+ order.code +" je poslata",
                                body: "Vaša porudžbina je poslata. \nSvi proizvodi iz Vaše porudžbine broj "+order.code+" su upakovani i poslati kuriskom službom.",
                                attachments:
                                    true, callback: this.callback})}
                                    className="btn btn-success">
                                <i className="fa fa-envelope mr-2" /> EMAIL + DOKUMENTA
                            </button>
                        </div>
                    </div>

                    <div className="mb-3">
                        {order.history?.map((item) => {
                            const status = order_status(item.status);
                            return <div key={item.id}>
                                <div className="d-flex pb-2">
                                    <span className="text-black-50 font-italic mr-2"> {formatTime(item.created_at)} </span>
                                    <span className="font-weight-bold" style={{color: status.color}}> {status.title} </span>
                                    {item.message &&
                                    <span className="prewrap mr-3 p-1 font-italic bg-light">{item.message}</span>}
                                    {item.admin_id &&
                                    <span className="ml-1 text-black-50"> {item.admin_name} </span>}
                                </div>
                            </div>
                        })}
                    </div>


                    <OrderNote id={order.id} reload={this.load} />
                </div>

                <Transaction order={order} />

                <div className="bg-white shadow p-3 mb-4">
                    <div className="row mb-3">
                        <div className="col-md-3">
                            <div className="font-weight-bold mb-3 border-bottom"> Osnovno </div>
                            <div className="mb-1">Cena</div>
                            <div className="mb-3">
                                <div className="font_11"><strong>{formatPrice(order.full_price)}</strong></div>
                            </div>
                            <div className="mb-1">Datum</div>
                            <div className="mb-3">
                                <strong>{formatDateTime(order.created_at)}</strong>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="font-weight-bold mb-3 border-bottom"> Kontakt </div>
                            {order.firm_name &&
                            <div>
                                <div className="mb-1">Firma i PIB</div>
                                <div className="mb-3">
                                    <strong>{order.firm_name}</strong>
                                    <div className="text-black-50">{order.firm_pib}</div>
                                </div>
                            </div>}

                            <div className="mb-1">Ime i prezime</div>
                            <div className="mb-3">
                                <strong>{order.name} {order.lastname}</strong>
                            </div>

                            <div className="mb-1">Telefon</div>
                            <div className="mb-3">
                                <strong>{order.phone}</strong>
                            </div>
                            <div className="mb-1">Email</div>
                            <div className="mb-3">
                                <strong>{order.email}</strong>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="font-weight-bold mb-3 border-bottom"> Adresa </div>
                            <div className="mb-1">Adresa</div>
                            <div className="mb-3">
                                <strong>{order.address}</strong>
                            </div>
                            <div className="mb-1">Grad</div>
                            <div className="mb-3">
                                <strong>{order.zip} {order.city}</strong>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="font-weight-bold mb-3 border-bottom"> Način plaćanja </div>
                            {payment_method(order.payment_method)}
                        </div>
                    </div>

                    {order.message &&
                    <div className="p-3 bcg">
                        <div className="font-weight-bold mb-3 border-bottom"> Dodatna poruka </div>
                        <div className="pre_wrap">{order.message}</div>
                    </div>}
                </div>

                <div className="bg-white shadow p-3 mb-4">
                    <table className="table">
                        <tbody>
                            {this.state.order.products.map((product) => {
                                console.log(product)
                                return <tr key={product.id}>
                                    <td width={140}>
                                        <img src={image(product.image_path)} alt={product.name} className="img-fluid" />
                                    </td>
                                    <td>
                                        <div className="mb-2">
                                            <strong>{product.name}</strong>
                                        </div>
                                        <div className="mb-1">
                                            <div className="mb-1">Dobavljac:  {product.supplier.name}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="mb-1">Cene u trenutku kupovine:</div>
                                        <div className="mb-1">Cena dobavljača:  <strong>{formatPrice(product.pivot.purchase_price)}</strong></div>
                                        <div className="mb-1">Izračunata cena:  <strong>{formatPrice(product.pivot.calculated_price)}</strong></div>
                                        <div className="mb-1">Sistem cena:  <strong>{formatPrice(product.pivot.recommended_price)}</strong></div>
                                        <div className="mb-1">Admin cena:  <strong>{formatPrice(product.pivot.admin_price)}</strong></div>
                                        <div className="mb-3 text-primary">
                                            <strong>PORUČENA CENA: {formatPrice(product.pivot.price)}</strong>
                                        </div>

                                    </td>
                                    <td>
                                        <div className="mb-1">Količina:</div>
                                        <div className="mb-3 font_12">
                                            <strong>{product.pivot.qty}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="mb-1">Ukupno:</div>
                                        <div className="mb-3 text-primary">
                                            <strong>{formatPrice(product.pivot.price * product.pivot.qty)}</strong>
                                        </div>
                                    </td>
                                </tr>
                            })}
                            <tr>
                                <td colSpan={4}> </td>
                                <td width={280}>
                                    <div className="mb-2 font-weight-bold">Ukupno:</div>
                                    <div className="mb-2 text-primary font_2"><strong>{formatPrice(order.full_price)}</strong></div>
                                    <div className="">{formatDateTime(order.created_at)}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        );
    }

    load = () => {
        getOrders({id: this.props.drawer.id, withHistory: true}).then((response) => {
            if(!response.data.seen) {
                patchPath({path: `orders.${this.props.drawer.id}.seen`, value: 1}).then((response) => {
                    this.props.closeDrawer("success");
                    if(this.props.drawer.reload)      {
                        this.props.drawer.reload();
                        this.props.setReloader("unseenOrders");
                    }
                });
            }
            this.setState({...this.state, order: response.data})
        })
    }

    componentDidMount() {
        this.load();
    }
}
const mapStateToProps = state => ({
    drawer: state.drawers.order,
    mailer: state.drawers.mailer
});
const mapDispatchToProps = {
    setReloader: setReloader,
    openDrawer: openDrawer,
    closeDrawer: closeDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderDrawer);
