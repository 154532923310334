import React from "react";
import moment from "moment";
import {fromNow, toSqlFormat} from "../../../providers/time";
import {patchPath} from "../../../app/hooks";

class DateTimePicker extends React.Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            value: this.props.value,
            default: ""
        }
    }

    changed = (e)   => {
        e.preventDefault();
        let value = this.myRef.current.value;
        if(!value) {
            value = null;
        } else  {
            value = toSqlFormat(moment(value, "DD.MM.YYYY, HH:mm:ss")).toString();
        }
        this.setState({...this.state, value: value});
        patchPath({path: this.props.path, value: value});
    }

    render = () => {
        return <form onSubmit={this.changed}>
            <div className="input-group">
                <input
                    placeholder={this.props.placeholder}
                    ref={this.myRef}
                    name={this.props.name}
                    defaultValue={this.state.default}
                    className="form-control" />
                <button type="submit" className="btn btn-primary input-group-append">Sačuvaj</button>
            </div>
            {this.state.value && fromNow(this.state.value)}
        </form>
    };

    componentDidMount() {
        let def = "";
        if(this.props.value)        {
            def = moment.utc(this.props.value).local().format("DD.MM.YYYY, HH:mm:ss").toString();
        }
        this.setState({...this.state, default: def})

        new window.dtsel.DTS('input[name="' + this.props.name +'"]',  {
            showTime: true,
            dateFormat: "dd.mm.YYYY",
            timeFormat: "HH:MM:SS"
        });
    }
}

export default DateTimePicker;
