
export function payment_method(method) {
    const methods = {
        1: "Plaćanje pouzećem",
        2: "Plaćanje preko računa",
        3: "Plaćanje karticom",
    }
    if(methods[method]) return methods[method];
}

export function order_statuses() {
    return {
        1: {id: 1, title: "U obradi", color: "#ff9e00"},
        2: {id: 2, title: "Primljeno", color: "#006fca"},
        3: {id: 3, title: "Poslato", color: "#009a56"},
        4: {id: 4, title: "Isporučeno", color: "#009a56"},
        5: {id: 5, title: "Otkazano", color: "#d00000"},
        6: {id: 6, title: "Reklamacija", color: "#d400b5"},
    }
}
export function order_status(method) {
    if(order_statuses()[method]) return order_statuses()[method];
    return {};
}