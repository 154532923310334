import moment from "moment";


export function formatDate(timestamp)    {
    return moment.utc(timestamp).local().format("DD.MM")
}
export function formatDateTime(timestamp)    {
    return moment.utc(timestamp).local().format("DD.MM.YYYY HH:mm:ss")
}
export function formatTime(timestamp)    {
    return moment.utc(timestamp).local().format("DD.MM HH:mm")
}
export function fromNow(timestamp)    {
    return moment.utc(timestamp).local().fromNow()
}
export function timeDiff(timestamp1, timestamp2)    {
    return moment.utc(timestamp2).from(moment.utc(timestamp1))
}
export function isBeforeNow(timestamp)   {
    return moment.utc(timestamp).local().diff() <= 0;
}
export function isAfterNow(timestamp)   {
    return moment.utc(timestamp).local().diff() >= 0;

}
export function toSqlFormat(momentTime) {
    const sqlFrmat = 'YYYY-MM-DD HH:mm:ss';
    return momentTime.utc().format(sqlFrmat);
}