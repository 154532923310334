import React from "react";
import {openDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";
import {getOrders, patchPath} from "../../../app/hooks";
import {formatPrice} from "../../../providers/price";
import {order_status, payment_method} from "../../../providers/order";
import Loader from "../../framework/Loader";
import {formatDateTime} from "../../../providers/time";
import OrderStatusChange from "../orders/OrderStatusChange";


class ActiveOrders extends React.Component {

    state = {
        orders: false,
        form: {
            limit: 200,
            all: true,
            active: true
        }
    }


    pin = (id, value) =>      {
        patchPath({path: `orders.${id}.pinned`, value: value}).then((response) => {
            this.load();
        })
    }

    render = () => {
        if(!this.state.orders)    {
            return <Loader />
        }
        if(this.state.orders.length === 0)    {
            return <div className="text-center p-3"> Nema aktivnih porudžbina </div>
        }
        return <div className="shadow bg-white">
            <h5 className="p-3 border-bottom d-flex justify-content-between align-items-center">
                Aktivne porudžbine
            </h5>
            <div>
                {this.state.orders.map((order) => {
                    const status = order_status(order.status);
                    return <div key={order.id} className="border-bottom p-2 row">
                        <div className="col-md-6">
                            <div className="font-weight-bold"> {order.name} {order.lastname} </div>
                            <div>{order.email} </div>
                            <div className="">{order.phone} </div>
                            <div className="mb-2"> <i className="font-weight-bold" /> {order.address}, {order.zip} {order.city} </div>
                            <button  onClick={() => this.props.openDrawer({name: "order", id: order.id, reload: this.load})}
                                className="btn btn-sm btn-light">
                                detaljnije
                            </button>
                        </div>
                        <div className="col-md-6 position-relative">
                            <div className="font-weight-bold"> {formatPrice(order.full_price)} </div>
                            <div className=""> {payment_method(order.payment_method)} </div>
                            <div className=""> {formatDateTime(order.created_at)} </div>
                              <div style={{color: status.color}}>
                                 <OrderStatusChange reload={this.load} order={order} />
                            </div>
                            {order.pinned
                                ? <button onClick={() => this.pin(order.id, null)} style={{top: 0, right: 14}}
                                          title="Zakačene porudžbine su uvek na vrhu, bez obzira na datum."
                                          className="btn btn-success btn-sm mb-2 position-absolute">
                                    <i className="fas fa-thumbtack" />
                                </button>
                                : <button onClick={() => this.pin(order.id, 1)}
                                          title="Zakačite porudžbinu da bude na vrhu." style={{top: 0, right: 14}}
                                          className="btn btn-light btn-sm mb-2 position-absolute">
                                    <i className="fas fa-thumbtack" />
                                </button>}
                        </div>
                    </div>
                })}
            </div>
        </div>
    }

    load = (form) => {
        if(!form) form = this.state.form;
        getOrders(form).then((response) => {
            this.setState({...this.state, orders: response.data});
        })
    }
    componentDidMount() {
        this.load();
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(ActiveOrders);