import React from "react";
import Loader from "../Loader";
import Pagination from "../Pagination";
import {getCategories, getSuppliers} from "../../../app/hooks";
import Select from "react-select";
import {getChildrenIds, unmarshal} from "../../../providers/categories";
import Checkbox from "../form/Checkbox";

class Table extends React.Component {

    state = {
        data: false,
        suppliers: [],
        categories: [],
        form: this.props.form ? {...{page: 1, search: "", limit: 40}, ...this.props.form} : {}
    }

    editField = (e) => {
        let newState;
        const name = e.target.name;
        const value = e.target.value;
        if(name === "page") {
            newState = {...this.state, form: {...this.state.form, [name]: value}};
        } else  {
            newState = {...this.state, form: {...this.state.form, [name]: value, page: 1}};
        }
        if(this.props.editField)    {
            this.props.editField(e);
        }
        this.setState(newState);
        this.scrollToTop();
        if(!this.props.noHistory)    {
            window.history.pushState(newState.form, "", this.route(newState.form));
        }
        this.load(newState.form);
    }

    editSelect = (field, item) => {
        if(item)    {
            this.editField({target: {name: field, value: item.id}});
        } else  {
            this.editField({target: {name: field, value: ""}});
        }
    }
    editCheckbox = (e) => {
        let val = e.target.value;
        if(!e.target.checked)   {
            val = "";
        }
        this.editField({target: {name: e.target.name, value: val}});
    }

    route = (form = false) => {
        if(!form) form = this.state.form;
        let params = [];
        for(let key in form) {
            let value = form[key];
            if(!value) continue;
            if(typeof value === "object")    {
                value = JSON.stringify(value);
            }
            if(Array.isArray(value))    {
                value = JSON.stringify(value);
            }
            params.push(`${key}=${value}`);
        }
        if(params.length === 0) {
            return "";
        }
        return "?" + params.join("&");
    }

    scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    render = () => {
        return <div>
            <div className="d-flex flex-wrap mb-3">
                <div className="mr-2" style={{width: 200}}>
                    <input  className={this.state.form.search ? "form-control border-primary border_3" : "form-control"}
                        name="search" type="text" autoComplete="off" placeholder="Pretraga..."
                          onChange={this.editField} value={this.state.form.search} />
                </div>
                {this.props.filters && this.props.filters.map((item) => {
                    return <div className="mr-2 mb-2" key={item.name} style={{flexBasis: item.break ? "100%" : ""}}>
                        {item.type === "supplier" &&
                        <Select
                            value={this.state.suppliers.find(option => option.id + "" === this.state.form[item.name])}
                            isClearable={true}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    border: this.state.form[item.name] ? "2px solid red" : "",
                                    width: 200,
                                }),
                            }}
                            placeholder="Dobavljači"
                            onChange={(item) => this.editSelect("supplier", item)}
                            options={this.state.suppliers}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id} />}

                        {item.type === "category" &&
                        <Select
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    border: this.state.form[item.name] ? "2px solid red" : "",
                                    width: 300,
                                }),
                            }}
                            className="border-primary border_3"
                            value={this.state.categories.find(option => option.id + "" === this.state.form[item.name])}
                            isClearable={true}
                            placeholder="Kategorije"
                            onChange={(item) => this.editSelect("category", item)}
                            options={this.state.categories}
                            getOptionLabel={option => `${option.lvl === 2 ? "- ": ""} ${option.lvl === 3 ? "-- ": ""} ${option.name}`}
                            getOptionValue={option => option.id} />}

                        {item.type === "select" &&
                        <select style={{width: 200}}
                            className={this.state.form[item.name] ? "form-control border-primary border_3" : "form-control"}
                                name={item.name} onChange={this.editField} value={this.state.form[item.name]}>
                            <option value="">{this.state.form[item.name] ? "Sve" : item.placeholder}</option>
                            {item.options.map((option) => {
                                return <option key={option.value} value={option.value}>
                                    {option.name}
                                </option>
                            })}
                        </select>}

                        {item.type === "checkbox" &&
                        <Checkbox
                            title={item.placeholder}
                            checked={!!this.state.form[item.name]}
                            name={item.name}
                            value={1}
                            onChange={this.editCheckbox}
                            true={<span className="text-success">{item.placeholder}</span>}
                            false={<span className="">{item.placeholder}</span>}/>}
                    </div>
                })}
            </div>

            <div className="d-flex justify-content-between align-items-end mb-2">
                <div className="font-weight-bold">
                    {this.state.data?.data?.length} od {this.state.data?.total}
                </div>
                <div className="form-inline">
                    prikaži:
                    <select
                        onChange={this.editField}
                        name="limit" value={this.state.form.limit} className="form-control ml-1 mr-2">
                        <option value={20}>20</option>
                        <option value={40}>40</option>
                        <option value={80}>80</option>
                        <option value={120}>120</option>
                        <option value={200}>200</option>
                    </select>

                    <Pagination
                        change={this.editField}
                        page={this.state.form.page}
                        lastPage={this.state.data.last_page} />
                </div>
            </div>

            <div className="table-responsive">
                <table className="table bg-white mb-2 shadow">
                    <thead>
                    <tr>
                        {this.props.columns.map((item) => {
                            return <td key={item.field}>
                                <button className="btn btn-light font-weight-bold"> {item.name} </button>
                            </td>
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.data?.data
                        ? this.state.data.data.length > 0
                            ? this.state.data.data.map((item) => {
                                let className = "";
                                if("seen" in item)  {
                                    className += item.seen ? " bg-white" : " bg-unseen";
                                }
                                return <tr key={item.id} className={className}>
                                    {this.props.columns.map((column) => {
                                        return <td key={column.field} width={column.width || ""}>
                                            {column.render
                                                ? column.render(item)
                                                : item[column.field]}
                                        </td>
                                    })}
                                </tr>
                            })
                            : <tr><td className="p-5 font-weight-bold" colSpan={this.props.columns.length}>
                                Prazna pretraga
                            </td></tr>
                        : <tr><td colSpan={this.props.columns.length}> <Loader /> </td></tr>}
                    </tbody>
                </table>
            </div>


            <div className="d-flex justify-content-end align-items-center">
                <Pagination
                    change={this.editField}
                    page={this.state.form.page}
                    lastPage={this.state.data.last_page} />
            </div>
        </div>
    }

    load = (form) => {
        let origForm = true;
        if(!form)   {
            origForm = false;
            if(!this.props.drawer && !this.props.noHistory)  {
                const query = document.location.search; // could be '?foo=bar'
                const urlParams =  new URLSearchParams(decodeURIComponent(query));
                const params = {};
                const keys = Object.keys(this.state.form);
                for(let i=0; i<keys.length; i++) {
                    let filter = urlParams.get(keys[i]) ? urlParams.get(keys[i]) : false;
                    if (filter) params[keys[i]] = filter;
                }
                form = {...this.state.form, ...params};
            } else  {
                form = this.state.form;
            }
        }
        if(form.category)  { // GET CHILDREN CATEGORIES
            const fullCategory = this.state.categories.find(item => item.id === form.category);
            if(fullCategory)    {
                form = {...form, categories: getChildrenIds(fullCategory)};
            }
        }

        this.props.hook(form).then((response) => {
            if(origForm)    {
                this.setState({...this.state, data: response.data});
            } else {
                this.setState({...this.state, data: response.data, form: form});
            }
        })
    }

    componentDidMount() {
        if(this.props.filters)  {
            for(let i=0; i<this.props.filters.length; i++)   {
                const item = this.props.filters[i];
                if(item.type === "supplier")     {
                    getSuppliers({all: true}).then((response) => {
                        this.setState({...this.state, suppliers: response.data});
                    });
                }
                if(item.type === "category")     {
                    getCategories({all: true, withChildren: true}).then((response) => {
                        const holder = unmarshal(response.data);
                        this.setState({...this.state, categories: holder});
                    });
                }
            }
        }
        this.load();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(document.location.search !== this.state.query)        {
            //this.load(this.state.form); // TODO PROBLEM sa back dugmetom
        }
        if(this.props.reload !== prevProps.reload)      {
            this.load();
        }
    }
}

export default Table;