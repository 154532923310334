import React from "react";
import {connect} from "react-redux";
import {getEponudaLogs} from "../../../app/hooks";
import Loader from "../../framework/Loader";
import {formatDateTime, fromNow} from "../../../providers/time";


class EponudaLogs extends React.Component {

    state = {
        logs: false
    }

    render = () => {
        if(!this.state.logs)    {
            return <Loader />
        }
        if(this.state.logs.length === 0)    {
            return <div className="text-center p-3"> Nema logova. </div>
        }
        return <div>
            <h5 className="">
                Ažuriranje Eponude (preuzet fajl)
            </h5>
            <div>
                {this.state.logs.map((log) => {
                    return <div key={log.id} className="p-1">
                        {formatDateTime(log.created_at)}
                        <span className="text-black-50 ml-2">
                            ({fromNow(log.created_at)})
                        </span>
                    </div>
                })}
            </div>
        </div>
    }

    load = () => {
        getEponudaLogs().then((response) => {
            this.setState({...this.state, logs: response.data});
        })
    }
    componentDidMount() {
        this.load();
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(EponudaLogs);