import React from "react";
import Checkbox from "../../framework/form/Checkbox";
import {patchEponudaConnect, patchEponudaModel, patchPath, putEponudaUrl} from "../../../app/hooks";
import {eimage} from "../../../app/helpers";



class Model extends React.Component {

    state = {
        name: [],
        model: this.props.product.model ? this.props.product.model.split(" ") : [],
        combined: [],
        products: false,
        url: ""
    }

    changed = (e, index) => {
        let value;
        if(e.target.checked)    {
            value = e.target.value;
        } else  {
            value = "";
        }
        let items = [...this.state.combined];
        items[index] = value;
        this.setState({...this.state, combined: items});
        let str = items.filter(Boolean).join(" ");
        if(str === "" || str === " ") str = null;
        patchPath({path: `products.${this.props.product.id}.model`, value: str});
        patchEponudaModel({model: str, id: this.props.product.id}).then((response) => {
            if(str) {
                this.setState({...this.state, products: response.data});
            } else {
                this.setState({...this.state, products: false});

            }
        })
    }

    closeDropDown = () => {
        this.setState({...this.state, products: false});
    }

    saveModel = (linkId) => {
        patchEponudaConnect({linkId: linkId, id: this.props.product.id}).then((response) => {
            this.closeDropDown();
            this.props.reload();
        })
    }

    saveUrl = (e) => {
        e.preventDefault();
        putEponudaUrl({productId: this.props.product.id, url: this.state.url}).then((response) => {
            this.closeDropDown();
            this.props.reload();
        })
    }

    render = () => {
        return <div className="">
            {this.state.name.map((item, index) => {
                const checked = this.state.combined[index] === item;
                return <span key={index} className="d-inline-block mr-2 text-center">
                    {item} <br />
                     <Checkbox
                         checked={checked}
                         value={item}
                         onChange={(e) => this.changed(e, index)} />
                </span>
            })}
            {this.state.products &&
            <div className="bg-light mb-4">
                {this.state.products.length > 0
                    ? <div>
                        <button onClick={this.closeDropDown} className="btn btn-link">
                            zatvorite
                        </button>
                        <a href={`https://www.google.com/search?q=${this.props.product.name} eponuda`}
                        target="_blank" rel="noreferrer">
                            google pretraga
                        </a>
                        {this.state.products.map((item) => {
                            return <div key={item.id} className="d-flex border-bottom align-items-center p-1">
                                <img src={eimage(item.eponuda_image_name)} alt="img" className="mr-1" width={30} />
                                {item.name}
                                <button
                                    onClick={() => this.saveModel(item.id)}
                                    className="btn btn-sm btn-success ml-1">selektuj</button>
                            </div>
                        })}
                    </div>
                    : <div className="pl-2 pt-2">
                    <a href={`https://www.google.com/search?q=${this.props.product.name} eponuda`}
                       target="_blank" rel="noreferrer">
                        google pretraga
                    </a>
                    <div className="text-black-50"> Nema poklapanja sa eponudom. Dodajte jos neki tag. </div>
                    </div>}

                    <form onSubmit={this.saveUrl} className="ml-2 mr-2 input-group">
                        <input
                            onChange={(e) =>
                                this.setState({...this.state, url: e.target.value})}
                            placeholder="ili unesite link sa eponude"
                            value={this.props.url}
                            required={true}
                            className="form-control form-control-sm"
                        />
                        <div className="input-group-append">
                            <button type="submit" className="btn btn-sm btn-primary">Sačuvaj</button>
                        </div>
                    </form>

                <button onClick={this.closeDropDown} className="btn btn-link">
                    zatvorite
                </button>
            </div>}
        </div>
    };

    componentDidMount() {
        let name = this.props.product.name.split(" ");
        name = name.map((item) => {
            return item.replace(/,+$/, '');
        });
        name = name.filter(item => item !== "");
        const combined = name.map((item) => {
            return this.state.model.includes(item) ? item : ""
        })
        this.setState({...this.state, combined: combined, name: name});
    }

}

export default Model;
