import React from 'react';
import ReactDOM from 'react-dom';
import "./assets/css/bootstrap.css";
import './assets/css/style.scss';
import App from './app/App';
import './app/axiosIntercept';
import './app/momentOverrides';

import { Provider } from "react-redux";
import store from "./store/store";

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
            <App />
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

