import React from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import routes from "../../app/routes";
import {getUnseenChatCount, getUnseenOrdersCount} from "../../app/hooks";


class LeftMenu extends React.Component {

    state = {
        unseenOrders: 0,
        unseenChat: 0,
    }

    render() {
        return (
            <div className="bg-white vh-100" style={{width: 300}}>
                <div className="position-fixed shadow_right bg-white vh-100 left_menu" style={{width: 300}}>
                    <Link to={routes.dashboard.route}>
                        <i className="fas fa-clipboard" /> {routes.dashboard.name}
                    </Link>
                    <Link to={routes.products.route}>
                       <i className="fa fa-tags" /> {routes.products.name}
                    </Link>
                    <Link to={routes.orders.route}>
                        {this.state.unseenOrders > 0 &&
                        <span style={{right: 5, width: 24, height: 24, borderRadius: 10}}
                            className="bg-danger d-inline-block font-weight-bold text-center position-absolute text-white">
                            {this.state.unseenOrders}
                        </span>}
                        <i className="fa fa-shopping-bag" /> {routes.orders.name}
                    </Link>
                    <Link to={routes.suppliers.route}>
                        <i className="fas fa-parachute-box" /> {routes.suppliers.name}
                    </Link>
                    <Link to={routes.categories.route}>
                        <i className="fa fa-align-right" /> {routes.categories.name}
                    </Link>
                    <Link to={routes.welcome.route}>
                        <i className="fas fa-pen-nib" /> {routes.welcome.name}
                    </Link>
                    <Link to={routes.chat.route}>
                        {this.state.unseenChat > 0 &&
                        <span style={{right: 5, width: 24, height: 24, borderRadius: 10}}
                              className="bg-danger d-inline-block font-weight-bold text-center position-absolute text-white">
                            {this.state.unseenChat}
                        </span>}
                        <i className="far fa-comment-dots" /> {routes.chat.name}
                    </Link>
                    <Link to={routes.live.route}>
                        <span className="btn btn-danger pt-1 pb-1 pl-3 pr-3"> {routes.live.name} ({Object.keys(this.props.live).length})</span>
                    </Link>
                </div>
            </div>
        );
    }

    loadUnseenOrders = () => {
        getUnseenOrdersCount().then((response) => {
            this.setState({...this.state, unseenOrders: response.data});
        });
    }
    loadUnseenChat = () => {
        getUnseenChatCount().then((response) => {
            this.setState({...this.state, unseenChat: response.data});
        });
    }

    componentDidMount() {
        this.loadUnseenOrders();
        this.loadUnseenChat();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.unseenOrdersReloader !== prevProps.unseenOrdersReloader)  {
            this.loadUnseenOrders();
        }
        if(this.props.unseenChatReloader !== prevProps.unseenChatReloader)  {
            this.loadUnseenChat();
        }
    }
}
const mapStateToProps = state => ({
    unseenOrdersReloader: state.reloaders.unseenOrders.status,
    unseenChatReloader: state.reloaders.unseenChat.status,
    live: state.live
});
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu);
