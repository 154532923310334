import React from "react";
import {connect} from "react-redux";
import Loader from "../framework/Loader";
import {getReports, postRunUpdate} from "../../app/hooks";
import {openDrawer} from "../../store/actions/drawersActions";
import ReportItem from "./ReportItem";
import ExcelUpload from "./ExcelUpload";


class Reports extends React.Component {

    state = {
        reports: false,
        startingUpdate: false,
        updating: false,
        form: {
            supplier: this.props.supplier?.id || "",
            page: 1,
            withSupplier: true,
            withErrors: true
        }
    }

    loadOlder = () => {
        const newState  = {...this.state, form: {...this.state.form, page: this.state.form.page + 1}};
        this.setState(newState);
        this.load(newState.form);
    }

    update = () => {
        if(!window.confirm("Zelite da pokrenete ažuriranje?"))  {
            return false;
        }
        postRunUpdate({id: this.props.supplier.id}).then((response) => {
            this.setState({...this.state, startingUpdate: true});
            setTimeout(() => {
                this.setState({...this.state, startingUpdate: false, updating: true});
                this.load();
            }, 2000)
        });
    }

    refresh = () => {
        this.setState({...this.state, reports: false});
        this.load();
    }

    render = () => {
        const supplier = this.props.supplier;
        return <div>
            {supplier && supplier.format === "excel" &&
            <div className="bg-white p-3 border mb-3">
                <ExcelUpload reload={this.load} supplierId={supplier.id} />
            </div>}

            {supplier && supplier.format !== "excel" &&
            <div className="mb-3">
                <button
                    disabled={this.state.updating}
                    onClick={this.update} className="btn mr-3 btn-info">
                    POKRENITE AŽURIRANJE
                </button>
            </div>}
            {this.state.startingUpdate && "Ažuriranje se pokreće... Sačekajte trenutak."}

            <div className="shadow bg-white">
                <div className="d-flex justify-content-between align-items-center border-bottom pl-3 pr-3 pt-2 pb-2">
                    <h5 className="mb-0">Ažuriranje</h5>
                    <button onClick={this.refresh} className="btn btn-light btn-sm">
                        REFRESH <i className="fa fa-refresh" />
                    </button>
                </div>
                <div>
                    {this.state.reports
                        ? this.state.reports.data.length > 0
                            ? <div>
                                {this.state.reports.data.map((item) => {
                                    return <ReportItem key={item.id} item={item} />
                                 })}
                                {this.state.reports.total > this.state.reports.data.length &&
                                <div
                                    onClick={this.loadOlder}
                                    className="p-3 text-center">
                                    <button className="btn-white btn">Učitajte starije</button>
                                </div>}
                            </div>
                            : <div className="p-3">
                                Nema izveštaja
                            </div>
                        : <Loader />}
                </div>
            </div>
        </div>
    }
    load = (form) => {
        if(!form) form = this.state.form;
        getReports(form).then((response) => {
            if(this.state.form.page > 1)    {
                this.setState({...this.state, reports: {...this.state.reports, data: [...this.state.reports.data, ...response.data.data]}});
                return false;
            }
            this.setState({...this.state, reports: response.data});
        });
    }
    componentDidMount() {
        this.load();
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Reports);