import React from "react";
import {disconnectLive, setLive} from "../../store/actions/liveActions";
import {connect} from "react-redux";
import {serverIO} from "../../app/helpers";

class Socket extends React.Component {

    state = {

    }

    get_aid()    {
        return localStorage.getItem("aid", false);
    }
    set_aid(uid)      {
        localStorage.setItem("aid", uid);
    }

    render = () => {
        return ""
    };

    componentDidMount() {
        var socket = window.io(serverIO());
        socket.on("connect", () => {
            const data = {"admin": "sdf34JNMko2D4as2"};
            const aid = this.get_aid();
            if(aid)     {
                data["uid"] = aid;
            }
            socket.emit("join", data);
        });
        socket.on("joined", (data) => {
            if("uid" in data)  {
                this.set_aid(data["uid"]);
            }
            socket.emit("ping");
        });

        socket.on("pong", (data) => {
            this.props.setLive(data);
        });
        socket.on("disconnect", (data) => {
            this.props.disconnectLive(data);
        });
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    setLive: setLive,
    disconnectLive: disconnectLive
};
export default connect(mapStateToProps, mapDispatchToProps)(Socket);
