import React from "react";
import Nestable from "react-nestable";
import Banner from "./Banner";
import {patchBannersOrder, putBanner} from "../../app/hooks";


class Banners extends React.Component {

    state = {
        open: this.props.open
    };

    addSlide = () => {
        putBanner({name: this.props.name}).then((response) => {
            if(this.props.reload)   {
                this.props.reload();
            }
        });
    };

    changedOrder = (items) => {
        console.log(items)
        patchBannersOrder({items: items}).then((response) => {

        });
    };

    renderItem = (props) => {
        return <Banner {...props} reload={this.props.reload} />
    };

    render = () => {
        let banners = this.props.banners;
        return <div>
            <div className="p-2">
                <div>
                    <div>
                        <button className="btn btn-primary btn-sm mr-3" onClick={this.addSlide}> Dodajte baner </button>
                        {!this.props.open &&
                        <button onClick={()=>this.setState({...this.state, open: !this.state.open})} className="btn btn-secondary">
                            <i className="fa fa-chevron-down mr-2" />
                            {this.state.open ? "Zatvorite" : "Otvorite"}
                        </button>}
                    </div>

                    {this.state.open &&
                    <div className="mt-2">
                        <Nestable
                            maxDepth={1}
                            onChange={this.changedOrder}
                            handler={<span className="nestable_collapse_icon"><i className="fa fa-arrows" /></span>}
                            items={banners}
                            renderItem={this.renderItem} />
                    </div>}
                </div>
            </div>
        </div>
    }

}
export default Banners;