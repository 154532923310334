import React from "react";
import Input from "../../framework/form/Input";
import {putCategories} from "../../../app/hooks";


class AddCategory extends React.Component {

    state = {
        open: false,
        form: {
            id: this.props.item?.id,
            parent: this.props.parent,
            name: "",
            slug: ""
        }
    }

    change = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({...this.state, form: {...this.state.form, [name]: value}});
    }

    submit = (e) => {
        e.preventDefault();
        putCategories(this.state.form).then((response) => {
            this.props.reload();
            if(!this.props.item) {
                this.setState({...this.state, form: {name: "", slug: ""}});
            }
        })
    }

    render = () => {
        const item = this.props.item;
        return <div>
            {!this.state.open &&
            <button onClick={() => this.setState({...this.state, open: !this.state.open})}
                    className="btn btn-info mb-2">
                {item ? "Prepravite" : this.props.parent ? "Dodajte podkategoriju" : "Dodajte kategoriju"}
            </button>}

            {this.state.open &&
            <form action="/" onSubmit={this.submit} className="form-inline bg-light p-2">
                {item &&
                <input type="hidden" name="id" value={item.id} />}
                {item &&
                <input type="hidden" name="parent" value={this.props.parent} />}
                <Input
                    onChange={this.change}
                    required={true}
                    update={this.state.form.name}
                    placeholder="Ime kategorije"
                    value={item ? item.name : ""} name="name"
                    maxLength={191} autoComplete="off"  /> &nbsp;

                <Input
                    onChange={this.change}
                    required={true}
                    slugify={true}
                    update={this.state.form.name}
                    placeholder="Slug kategorije"
                    value={item ? item.slug : ""} name="slug"
                    maxLength={191} autoComplete="off"  />

                &nbsp; <button type="submit" className="btn btn-info">Sačuvajte</button>
                <button title="Zatvorite." onClick={() => this.setState({...this.state, open: false})}
                    type="button" className="btn btn-danger ml-1"><i className="fa fa-close" /></button>
            </form>}
        </div>
    };
}
export default AddCategory;
