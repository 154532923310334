import axios from "axios";
import store from "../store/store";
import {openDrawer} from "../store/actions/drawersActions";
import {logoutAdmin} from "../providers/adminProvider";

axios.interceptors.request.use(function (config) {
    if(store.getState().admin)       {
        var ajwt = store.getState().admin.ajwt;
        config.headers['adminauthorization'] = 'Bearer ' + ajwt;
    }
    return config;
}, function (error) {
    // Do something with request error
    // return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    if(response.data.success || response.data === "Ok.")   {
        store.dispatch(openDrawer({name: "success", content: response.data.success}));
    }
    return response;

}, function (error) {
    if(error.response)      {
        if(error.response.status === 404)       {
            return Promise.reject('Page not found!');
        }
        else if(error.response.status === 401)       {
            logoutAdmin(true);
        }
        else if(error.response.status === 402)       {

        }
        else if(error.response.status === 403)       {
            store.dispatch(openDrawer({name: "error", content: error.response.data}));
            return Promise.reject(error.response.data);
        }
        else if(error.response.status === 422)       {
            store.dispatch(openDrawer({name: "error", content: error.response.data}));
            return Promise.reject(error.response.data);
        }
        else        {
            return Promise.reject(error.response.data);
        }
        return Promise.reject(error.response.data);
    }
    return Promise.reject('Error with server');
});
