import React from "react";
import {connect} from "react-redux";
import Loader from "../../framework/Loader";
import {
    deleteBlock,
    getBlocks,
    getCategories,
    patchBlocksOrder,
    putBlockBanners,
    putBlockCarousel
} from "../../../app/hooks";
import Nestable from "react-nestable";
import Block from "./Block";
import {unmarshal} from "../../../providers/categories";


class Blocks extends React.Component {

    state = {
        categories: [],
        blocks: false
    }

    addBanners = () => {
        putBlockBanners().then((response) => {
           this.load();
        });
    }
    addCarousel = () => {
        putBlockCarousel().then((response) => {
            this.load();
        });
    }

    deleteBlock = (id) => {
        if (!window.confirm("Želite da obrišete blok?")) {
            return false;
        }
        deleteBlock({id: id}).then((response) => {
            this.load();
        });
    }

    blocksOrder = (items) => {
        patchBlocksOrder({items: items});
    }

    render = () => {
        if(!this.state.blocks)  {
            return <Loader />
        }
        return <div>
            <div className="mb-3">
                <button className="btn btn-primary mr-2" onClick={this.addBanners}> Dodajte banere </button>
                <button className="btn btn-primary" onClick={this.addCarousel}> Dodajte carousel </button>

                <span className="ml-3 font-weight-bold font_2">Baneri i carousels</span>
            </div>

            <div className="mt-3">
                <Nestable
                    maxDepth={1}
                    onChange={this.blocksOrder}
                    handler={<span className="nestable_collapse_icon"><i className="fa fa-arrows" /></span>}
                    items={this.state.blocks}
                    renderItem={(props) => <Block {...props} reload={this.load} categories={this.state.categories} />} />
            </div>
        </div>
    }

    load = () => {
        getBlocks({all: true}).then((response) => {
            this.setState({...this.state, blocks: response.data});
        })
    }

    componentDidMount() {
        getCategories({all: true, withChildren: true}).then((response) => {
            const holder = unmarshal(response.data);
            this.setState({...this.state, categories: holder});
            this.load();
        });

    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Blocks);