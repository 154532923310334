import Empty from "../views/framework/Empty";
import {fromNow} from "./time";

export function processPrice(product) {
    if (product.admin_price) { // TODO proverit countdown
        return product.admin_price;
    }
    if (product.recommended_price) {
        return product.recommended_price;
    }
    if (product.calculated_price) {
        return product.calculated_price;
    }
    return "";
}

export function formatPrice(price) {
    if (!price && price !== 0) return '';
    price = parseFloat(price).toFixed(2);
    let strPrice = String(price);
    if (price > 999) {
        const pos = -6;
        strPrice = strPrice.slice(0, pos) + " " + strPrice.slice(pos);
    }
    if (price > 999999) {
        const pos = -10;
        strPrice = strPrice.slice(0, pos) + " " + strPrice.slice(pos);
    }
    return strPrice.replace(".", ",");
}

export function renderPrice(item) {
    const price = processPrice(item);
    const pdv = (item.pdv === 0) ? "0" : item.pdv;
    const margo = (item.margo === 0) ? "0" : item.margo;
    let priceAndPdv = false;
    let minMargo = false;
    if(item.pdv)    {
        priceAndPdv = item.purchase_price * (1 + parseInt(item.pdv) / 100);
    }
    if(item.min_margo && priceAndPdv)  {
        minMargo = (priceAndPdv / 100) * parseFloat(item.min_margo);
    }
    return <div>

        <table className="table table-sm table-bordered text-center text-nowrap mb-1">
            <tbody>
            <tr>
                <td title="Cena od dobavljaca (u fajlu, sistem azurira).">
                    Kupovna <br /> {formatPrice(item.purchase_price)}
                </td>
                <td title={`PDV na kupovnu cenu.`}>
                    Pdv <br /> {pdv || <span className="text-danger">prazno</span>}
                </td>
                <td title={`MARZA za kupovnu cenu."`}>
                    Marža <br /> {margo || <span className="text-danger">prazno</span>}
                </td>
                <td title="Kupovna cena + PDV + MARŽA">
                    Izračunata <br />
                    <span className={price === item.calculated_price ? "text-success font-weight-bold" : ""}>
                            {formatPrice(item.calculated_price)}
                        </span>
                </td>
            </tr>
            <tr>
                <td title="Najniza cena na Eponudi.">
                    Eponuda <br /> {item.min_price ? formatPrice(item.min_price) : <Empty />}
                </td>
                <td colSpan="2" title={`MARZA ispod koje se ne ide. (procenat od kupovne cene + pdv)"`}>
                    Min marža <br /> {item.min_margo
                    ? <div className="font_09">
                        {item.min_margo}
                        {minMargo && <span title="U dinarima." className="ml-1 text-black-50">{minMargo.toFixed(2)}</span>}
                    </div>
                    : <Empty />}
                    {priceAndPdv && minMargo &&
                    <div title="Minimum cena ispod koje se ne ide: kupovna cena x pdv + min marža)">
                        {formatPrice(priceAndPdv + minMargo)}
                    </div>}
                </td>
                <td title="Cenu postavlja sistem analizom ostalih cena.">
                    SISTEM <br />  {item.recommended_price
                    ? <span className={price === item.recommended_price ? "text-success font-weight-bold" : ""}>
                                     {formatPrice(item.recommended_price)}
                                </span>
                    : <Empty/>}
                </td>
            </tr>
            </tbody>
        </table>

        {item.min_price &&
        <div className="mb-1" title="Razlika cena na Eponudi i trenutna cena na sajtu.">
            Razlika (eponuda): &nbsp;
            <span style={{fontSize: 18, fontWeight: "bold"}} className={(processPrice(item) - item.min_price) <= 0 ? "text-success" : "text-danger"}>
                    {formatPrice(processPrice(item) - item.min_price)}
                </span>
        </div>}

        {item.recommended_price &&
        <div title="Razlika izmedju cene koju kreira sistem i izračunate cene (kupovna + pdv + marža)">
            Razlika (marža): <b>{formatPrice(item.recommended_price - item.calculated_price)}</b>
        </div>}

        <div className="mt-1" title="Cena koju postavlja admin. Overriduje sve ostale cene.">
            Admin cena: {item.admin_price
            ? <span className={price === item.admin_price ? "text-success font-weight-bold" : ""}>
                    {formatPrice(item.admin_price)}
                </span>
            : <Empty/>}
        </div>
        {item.admin_discount_price &&
        <div>
            Cena pre popusta: <del>{formatPrice(item.admin_discount_price)}</del>
        </div>}

        {item.admin_price &&
        <>
            <div title="Početak ADMIN CENE. Ako nije postavljeno, cena je aktivna posle postavljanja.">
                Početak: {item.countdown_start ? fromNow(item.countdown_start) : <Empty/>}
            </div>
            <div title="Kraj ADMIN CENE. Ako nije postavljeno, cena ne ističe.">
                Kraj: {item.countdown_end ? fromNow(item.countdown_end) : <Empty/>}
            </div>
        </>}
    </div>
}