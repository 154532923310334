import React from "react";
import {connect} from "react-redux";
import {openDrawer, closeDrawer} from "../../../store/actions/drawersActions";
import {deleteCategories, patchPath} from "../../../app/hooks";
import AddCategory from "./AddCategory";
import {image} from "../../../app/helpers";
import Textarea from "../../framework/form/Textarea";



class CategoryItem extends React.Component {

    state = {
        open: false
    }

    selected = () => {
        if(this.props.drawer.callback)  {
            this.props.drawer.callback(this.props.item);
        }
        this.props.closeDrawer("categories");
    }

    delete = () => {
        if(!window.confirm("Zelite da obrisete kategoriju?"))  {
            return false;
        }
        deleteCategories({id: this.props.item.id}).then((response) => {
           this.props.reload();
        });
    }

    update = (field, value) => {
        patchPath({path: `categories.${this.props.item.id}.${field}`, value: value}).then((response) => {
            if(this.props.reload)   {
                this.props.reload();
            }
        })
    }

    render = () => {
        const item = this.props.item;

        return <div className="bg-white border">
            <div className="d-flex justify-content-between align-items-center">
                <div className="flex-grow-1 p-2">
                    <span className="mr-1"> {this.props.collapseIcon} </span>
                    {this.props.handler}
                    <span> {item.name}</span> &nbsp;
                </div>
                <div>
                    <button onClick={() => this.setState({...this.state, open: !this.state.open})} className="btn btn-light">
                        {this.state.open
                            ? <i className="fa fa-chevron-up" />
                            : <i className="fa fa-chevron-down" />}
                    </button>
                </div>
                {this.props.drawer.callback &&
                <div>
                    <button onClick={this.selected} className="btn btn-primary btn-sm ml-2">Selektujte</button>
                </div>}
            </div>
            {this.state.open &&
            <div className="p-3">
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3" style={{width: 200}}>
                            <button onClick={() => this.props.openDrawer({name: "files", bucket: "gointerface",
                                callback: (images) => this.update("image_id", images[0].id)})}>
                                <img src={image(item.image_path, false, item.image_bucket)} className="img-fluid" alt={item.image_name} />
                            </button>
                        </div>
                        <div className="mb-2">
                            <AddCategory item={this.props.item} reload={this.props.reload} />
                        </div>
                        <div className="mb-2">
                            <AddCategory parent={this.props.item.id} reload={this.props.reload} />
                        </div>
                        <button onClick={this.delete} className="btn btn-danger">Obrišite</button>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <Textarea
                                submit={true}
                                title={<div className="font-weight-bold"> SEO TITLE </div>}
                                path={`categories.${item.id}.seo_title`}
                                value={item.seo_title} name="seo_title" />
                        </div>
                        <div className="mb-3">
                            <Textarea
                                submit={true}
                                title={<div className="font-weight-bold"> SEO DESCRIPTION </div>}
                                path={`categories.${item.id}.seo_description`}
                                value={item.seo_description} name="seo_description" />
                        </div>
                        <Textarea
                            submit={true}
                            title={<div className="font-weight-bold"> SEO KEYWORDS </div>}
                            path={`categories.${item.id}.seo_keywords`}
                            value={item.seo_keywords} name="seo_keywords" />
                    </div>
                </div>
            </div>}
        </div>
    };
}
const mapStateToProps = state => ({
    drawer: state.drawers.categories
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(CategoryItem);
