import {SET_RELOADER} from "../actions/reloadersActions";

const reloaders = {
    unseenOrders:  {status: false},
    unseenChat:  {status: false}
}

const reloadersReducer = function(state = reloaders, {type, payload})      {
    switch(type)        {
        case SET_RELOADER:
            if(payload.name)        {
                return {...state, [payload.name]: {...reloaders[payload.name], ...payload, status: !state[payload.name].status}}
            }
            return {...state, [payload]: {status: !state[payload].status}};
        default:
            return state;
    }
}
export default reloadersReducer;