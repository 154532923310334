

export function unmarshal(categories, acc=[], lvl=1)   {
    for(let i=0; i<categories.length; i++)      {
        const item = categories[i];
        item.lvl = lvl;
        acc.push(item);
        if(item.children.length > 0)    {
            unmarshal(item.children, acc, lvl+1);
        }
    }
    return acc;
}
export function getChildrenIds(category) {
    let holder = [category.id];
    return loopChildren(category.children, holder);
}
export function loopChildren(items, holder) {
    if(items)   {
        for(let i=0; i<items.length; i++)       {
            holder.push(items[i].id);
            holder = loopChildren(items[i].children, holder);
        }
    }
    return holder;
}
