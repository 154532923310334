import React from "react";
import {patchPath} from "../../../app/hooks";

class Checkbox extends React.Component {

    state = {
        checked: !!this.props.checked
    }

    changed = (e) => {
        var checked = e.target.checked;
        var val = e.target.value;
        this.setState({...this.state, checked: checked});
        if(!checked)    {
            val = null;
        }
        if(this.props.path)     {
            if(this.props.invert)   {
                if(val === null) val = 1;
                else    val = null;
            }
            patchPath({path: this.props.path, value: val}).then((response) => {
                if(this.props.onChange)    {
                    this.props.onChange(e);
                }
            })
        } else  {
            if(this.props.onChange)    {
                this.props.onChange(e);
            }
        }
    }

    render = () => {
        return <label className="chbx" title={this.props.title || ""}>
            <input
                type="checkbox"
                onChange={this.changed}
                value={this.props.value || 1}
                checked={this.state.checked}
                name={this.props.name || ""}
                disabled={this.props.disabled} /><span className="mr-2" />

            {this.state.checked && (this.props.true || "")}
            {!this.state.checked && (this.props.false || "")}
            </label>
    };
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.checked !== prevProps.checked)    {
            this.setState({...this.state, checked: this.props.checked});
        }
    }
}

export default Checkbox;
