import { combineReducers, createStore } from "redux";

import drawers from "./reducers/drawersReducer";
import admin from "./reducers/adminReducer";
import reloaders from "./reducers/reloadersReducer";
import live from "./reducers/liveReducer";


const store = createStore(combineReducers({
    drawers: drawers,
    admin: admin,
    reloaders: reloaders,
    live: live
}));

export default store;