import {OPEN_DRAWER, CLOSE_DRAWER} from "../actions/drawersActions";

const drawers = {
    swipeMenu:  {status: false},
    product:    {status: false},
    products:    {status: false},
    success:    {status: false},
    error:       {status: false},
    supplier:    {status: false},
    categories:    {status: false},
    order:    {status: false},
    files:    {status: false},
    mailer:    {status: false},
    productsTransfer:    {status: false},
}

const drawersReducer = function(state= drawers, {type, payload})      {
    if(payload?.e)        {
        payload.e.preventDefault();
    }
    switch(type)        {
        case OPEN_DRAWER:
            if(payload.name)        {
                return {...state, [payload.name]: {...drawers[payload.name], ...payload, status: true}}
            }
            return {...state, [payload]: {status: true}};
        case CLOSE_DRAWER:
            if(state[payload]?.onClose)     {
                state[payload].onClose();
            }
            return {...state, [payload]: {status: false}};
        default:
            return state;
    }
}
export default drawersReducer;