import React from "react";
import {openDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";
import {getSuppliersCategories} from "../../../app/hooks";
import Input from "../../framework/form/Input";


class SuppliersCategories extends React.Component {

    state = {
        categories: [],
        form: {
            supplier: this.props.supplierId || "",
            withError: this.props.supplierId ? "" : true,
            withSupplier: true,
            withCategory: true,
            withProductsCount: true
        }
    }

    render = () => {
        if(this.state.categories.length === 0)    {
            return "";
        }
        return <div className="shadow bg-white">
            <h5 className="p-3 border-bottom">Kategorije dobavljača
                {!this.props.supplierId && "(bez pdv-a ili marže)"}
            </h5>
            <div>
                {this.state.categories.map((item) => {
                    return <div key={item.id} className="border-bottom p-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <a href="/" onClick={(e) => this.props.openDrawer({e: e, name: "supplier", id: item.supplier.id})}>
                                    {item.supplier?.name}
                                </a>: {item.category?.name}
                                <div> Proizvodi: {item.products_count} </div>
                            </div>
                            <div className="d-flex">
                                <div className="mr-1">
                                    <div>pdv</div>
                                    <Input
                                        nullable={true}
                                        width={80}
                                        submit={true}
                                        path={`categories_suppliers.${item.id}.pdv`}
                                        type="number"
                                        value={(item.pdv === 0) ? "0" : item.pdv} name="pdv"
                                        maxLength={4} autoComplete="off"  />
                                </div>
                                <div className="mr-1">
                                    <div>marža</div>
                                    <Input
                                        nullable={true}
                                        width={80}
                                        submit={true}
                                        path={`categories_suppliers.${item.id}.margo`}
                                        type="number"
                                        value={(item.margo === 0) ? "0" : item.margo} name="margo"
                                        maxLength={4} autoComplete="off"  />
                                </div>
                                <div>
                                    <div>min marža</div>
                                    <Input
                                        placeholder="%"
                                        nullable={true}
                                        submit={true}
                                        width={120}
                                        path={`categories_suppliers.${item.id}.min_margo`}
                                        type="number"
                                        value={item.min_margo} name="min_margo"
                                        maxLength={4} autoComplete="off"  />
                                </div>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    }

    load = (form) => {
        if(!form) form = this.state.form;
        getSuppliersCategories(form).then((response) => {
            this.setState({...this.state, categories: response.data});
        })
    }
    componentDidMount() {
        this.load();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.reload && this.props.reload !== prevProps.reload)      {
            this.load();
        }
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(SuppliersCategories);