import React from "react";
import {connect} from "react-redux";
import update from "react-addons-update";
import {loginAdmin} from "../../providers/adminProvider";
import {postAdminLogin} from "../../app/hooks";
import logo from "../../assets/images/logo.png";
import {Redirect} from "react-router-dom";
import routes from "../../app/routes";

class Login extends React.Component {

    form = {
        email: "",
        password: "",
        remember: false,

    }
    state = {
        form: this.form,
        error: {},
        success: false,
        loading: false
    };

    changeField = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        if(e.target.type === "checkbox")       {
            value = !!e.target.checked;
        }
        this.setState(update(this.state, {form: {[name]: {$set: value}} }));
    };

    submit = (e) => {
        e.preventDefault();
        this.setState({...this.state, loading: true, error: {}});
        postAdminLogin(this.state.form).then((response) => {
            this.setState({...this.state, loading: false, success: true, error: {}});
            let admin = loginAdmin(response, this.state.form.remember);
            if(admin) {
                window.location.reload();
            }
        }).catch((error) => {
            this.setState({...this.state, loading: false, error: error, success: false});
        });
    };

    render = () => {
        if(this.props.admin) return <Redirect to={routes.dashboard.route}  />
        let form = this.state.form;
        return <div className="d-flex vh-100 align-items-center justify-content-center">

            <div className="bg-white shadow p-3" style={{width: 360}}>
                <form action="#" method="POST" onSubmit={this.submit}>
                    <div className="text-center mb-4">
                        <img src={logo} alt="logo" className="img-fluid" width={250} />
                    </div>
                    <div>
                        <input
                            onChange={this.changeField}
                            name="email"
                            value={form.email}
                            placeholder="Email"
                            type="email"
                            className="mb-3 form-control form-control-lg"
                            required={true}
                        />
                        {this.state.error.email &&
                        <div className="alert alert-danger">{this.state.error.email}</div>}
                    </div>

                    <div>
                        <input
                            type="password"
                            onChange={this.changeField}
                            name="password"
                            value={form.password}
                            placeholder="Lozinka"
                            className="form-control mb-3 form-control-lg"
                            required={true}
                        />
                        {this.state.error.password &&
                        <div className="alert alert-danger">{this.state.error.password}</div>}
                    </div>

                    <label className="mb-3">
                        <input
                            type="checkbox"
                            name="remember"
                            checked={form.remember}
                            onChange={this.changeField}
                            value="1"
                            className="mr-2 font_2"
                        />
                        Zapamti me?
                    </label>
                    <button type="submit" disabled={this.state.loading} className="btn btn-lg btn-block btn-primary mb-3">
                        Ulogujte se
                    </button>
                </form>

                {this.state.error.message &&
                <div className="alert alert-danger mb-3">
                    {this.state.error.message}
                </div>}

                {this.state.success &&
                <div className="alert alert-success mb-3">
                    Uspešno ste ulogovani.
                </div>}
            </div>

        </div>
    }
    componentDidMount() {
        document.body.className = "cms";
    }
}
const mapStateToProps = state => ({
    admin: state.admin
});
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
