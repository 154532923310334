import React from "react";
import Table from "../../framework/table/Table";
import {getProducts, patchEponudaModel} from "../../../app/hooks";
import Checkbox from "../../framework/form/Checkbox";
import {eimage, image} from "../../../app/helpers";
import {formatDateTime, fromNow} from "../../../providers/time";
import Empty from "../../framework/Empty";
import moment from "moment";
import Model from "./Model";
import {formatPrice, renderPrice} from "../../../providers/price";
import {closeDrawer, openDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";


class Products extends React.Component {

    default = { withSupplier: true, withCategory: true, withEponuda: true,
        supplier: "", category: "", state: "", price: "", order: "", eponuda: ""}
    state = {
        reload: false,
        selected: [],
        form: this.props.drawer.form ? {...this.default, ...this.props.drawer.form} : this.default
    }

    deleteModel = (id) => {
        if(!window.confirm("Zelite da uklonite mapiran predmet?"))  {
            return false;
        }
        patchEponudaModel({id: id, model: null}).then((response) => {
            this.setState({...this.state, reload: !this.state.reload});
        });
    }

    reload = () => {
        this.setState({...this.state, reload: !this.state.reload});
    }

    toggleSelected = (e) => {
        const value = e.target.value;
        if(e.target.checked)    {
            this.setState({...this.state, selected: [...this.state.selected, value]});
        } else  {
            const selected = this.state.selected.filter(item => item !== value);
            this.setState({...this.state, selected: selected});
        }
    }

    callback = () => {
        this.props.drawer.callback(this.state.selected);
        this.props.closeDrawer("products");
    }

    renderCheck = (item) => {
       return <Checkbox
            value={item.id}
            onChange={this.toggleSelected}
            checked={this.state.selected.includes(item.id)}
            name="check" />
    }

    renderState = (item) => {
        return <div>
            <div>
                <Checkbox
                    disabled={true}
                    title="Stanje proizoda postavljen azuriranjem (samo sistem moze da menja)."
                    checked={!!item.state}
                    name="state"
                    true={<span className="text-success">Na stanju</span>}
                    false={<span className="text-danger">Nema na stanju</span>}/>
                {item.duplicate &&
                <button onClick={() => this.props.openDrawer({name: "products", form: {search: item.ean}, onClose: this.reload})}
                    className="btn btn-sm btn-danger mb-2">Dupli barkod</button>}
            </div>
            <div>
                <Checkbox
                    title="Deaktivirani proizvodi se ne prikazuju kupcima."
                    checked={!!item.active}
                    path={`products.${item.id}.active`}
                    name="active"
                    true={<span className="text-success">Aktiviran</span>}
                    false={<span className="text-danger">Deaktiviran</span>}/>
            </div>
            <div>
                <Checkbox
                    title="Rasprodati proizvodi se prikazuje ali ne moze da se poruci."
                    checked={!!item.supply}
                    path={`products.${item.id}.supply`}
                    name="supply"
                    true={<span className="text-success">Nije rasprodato</span>}
                    false={<span className="text-danger">Rasprodato</span>}/>
            </div>

            <div style={{opacity: item.model ? 1 : 0.3}}>
                <Checkbox
                    disabled={!item.model}
                    invert={true}
                    title="Deaktivirani proizvodi se ne dodaju u Eponuda fajl."
                    checked={!item.eponuda_removed}
                    path={`products.${item.id}.eponuda_removed`}
                    name="eponuda_removed"
                    true={<span className="text-success">Eponuda (aktiv.)</span>}
                    false={<span className="text-danger">Eponuda (deakt.)</span>}/>
            </div>
            <div title="Azurira sistem. Neki dobavljaci nemaju zalihe, ali su na stanju.">
                Zalihe: <Empty value={(item.stock === 0) ? "0" : item.stock}/>
            </div>
            {item.min_price && !item.gogorila_price &&
            <div className="alert alert-danger p-2 mt-2" title="Ima u fajlu, ali nema na Eponudi!">
                <i className="fas fa-exclamation-triangle" /> Nema na EP
            </div>}

        </div>
    }

    renderInfo = (item) => {
        return <div>
            <div className="d-flex align-items-center w-100">
                <div className="mr-3" style={{width: 80}}>
                    <img src={image(item.image_path)} alt={item.name} className="img-fluid"/>
                    <div>
                        <button
                            className="btn btn-primary btn-sm pl-1 pr-1 mt-2"
                            onClick={() => this.props.openDrawer({name: "product", id: item.id, onClose: this.reload})}>
                            Pogledajte
                        </button>
                    </div>
                </div>
                <div className="flex-grow-1">
                    <Model product={item} reload={() => this.setState({...this.state, reload: !this.state.reload})} />
                    <div>Kategorija: {item.category?.name}</div>
                    <div>Model: <Empty value={item.model}/></div>
                    <div>Brend: <Empty value={item.brand}/></div>
                    <div>Barkod: <Empty value={item.ean}/></div>
                    <div>Dobavljač:
                        <a href="/" onClick={(e) => this.props.openDrawer({e: e, name: "supplier", id: item.supplier?.id})} className="ml-2">
                            <b>{item.supplier?.name} </b>
                        </a>
                    </div>
                </div>
            </div>
            {item.eponuda_link &&
            <div className="border-top mt-1 pt-1 bg-light position-relative">
                <button
                    onClick={() => this.deleteModel(item.id)}
                    className="btn btn-light btn-sm position-absolute" style={{top: 0, right: 0}}><i className="fa fa-close" /></button>
                <div className="d-flex align-items-center">
                    <div className="mr-3 text-center" style={{width: 80}}>
                        <img src={eimage(item.eponuda_image_name, false, "eponuda")} alt={item.eponuda_name} className="img-fluid"/>
                    </div>
                    <div>
                        {item.eponuda_name &&
                        <div title="Ime proizvoda na Eponudi.">
                            {item.eponuda_name}
                        </div>}
                        <div title="Link ka proizvodu na Eponudi.">
                            <a href={"https://eponuda.com" + item.eponuda_link} rel="noreferrer" target="_blank">
                                {"https://eponuda.com" + item.eponuda_link}
                            </a>
                        </div>
                        <div className="d-flex">
                            <div className="mr-3" title="Najniza cena na Eponudi.">
                                Min cena: {item.min_price ? formatPrice(item.min_price) : <Empty />}
                            </div>
                            {item.max_price &&
                            <div className="mr-3" title="Najvisa cena na Eponudi.">
                                Max cena: {item.max_price ? formatPrice(item.max_price) : <Empty />}
                            </div>}
                            {item.gogorila_price &&
                            <div className="mr-3" title="GoGOrila cena na Eponudi.">
                                GoGorila cena: {item.gogorila_price ? formatPrice(item.gogorila_price) : <Empty />}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>}

        </div>
    }

    updated = (item) => {
        const diff = moment().local().diff(moment.utc(item.updated_timestamp).local(), "hour");
        let color = "#27ae60";
        if (diff > 24) color = "#f1c40f";
        if (diff > 72) color = "#e74c3c";

        return <div>
            {item.updated_timestamp &&
            <div className="font-weight-bold" style={{color: color}} title={`Ažurirano: ${formatDateTime(item.updated_timestamp)}`}>
                {fromNow(item.updated_timestamp)}
            </div>}
            <div title="Ukupan broj pregleda proizvoda."> Broj pregleda: {item.clicks} </div>
            <div title="Klikovi sa Eponude."> Eponuda klikovi: {item.eponuda_clicks} </div>
            <div title="Sifra iz fajla.">ID: {item.id}</div>
            <div title="Sifra iz fajla.">Šifra: {item.code}</div>
        </div>
    }

    render = () => {
        let columns = [
            {field: "state", name: "Stanje", width: 180, render: this.renderState},
            {field: "price", name: "Cena", width: 260, render: renderPrice},
            {field: "name", name: "Informacije", render: this.renderInfo},
            {field: "updated_timestamp", name: "Statistika", width: 200, render: this.updated}
        ];
        if(this.props.drawer.callback)      {
            columns = [...[{field: "checkbox", name: "Cekiraj", width: 30, render: this.renderCheck}], ...columns];
        }
        return <div>
            {this.props.drawer.callback
                ? <button
                    onClick={this.callback}
                    className="btn btn-success position-fixed" style={{bottom: 10, zIndex: 100}}>
                    IZABERI SELECTOVANE PROIZVODE
                </button>
                : <div className="text-right">
                    <button className="btn btn-info"
                        onClick={() => this.props.openDrawer({name: "product", onClose: this.reload})}>
                        Dodajte proizvod
                    </button>
                </div>}
            <div className="container-fluid">

                <Table
                    columns={columns}
                    filters={[{name: "supplier", type: "supplier"},
                        {name: "category", type: "category"},
                            {name: "state", placeholder: "Stanje", type: "select", options: [
                                {name: "Na stanju", value: "state"}, {name: "Nema na stanju", value: "no_state"},
                                {name: "Aktiviran", value: "active"}, {name: "Deaktiviran", value: "no_active"},
                                {name: "Eponuda (aktiv)", value: "eponuda_active"}, {name: "Eponuda (deaktiv)", value: "eponuda_no_active"},
                                {name: "Nije rasprodato", value: "supply"},{name: "Rasprodato", value: "no_supply"},
                                    {name: "Obrisano", value: "deleted"}
                            ]},
                            {name: "price", placeholder: "Cena", type: "select", options: [
                                {name: "Nema PDV", value: "no_pdv"}, {name: "Nema MARŽU", value: "no_margo"},
                                    {name: "Nema MIN MARŽU", value: "no_min_margo"},
                                    {name: "Sistem cena", value: "sistem_price"},
                                    {name: "Admin cena", value: "admin_price"}, {name: "Admin cena (start)", value: "admin_price_start"},
                                {name: "Admin price (kraj)", value: "admin_price_end"},
                            ]},
                            {name: "info", placeholder: "Informacije", type: "select", options: [
                                {name: "Bez slike", value: "no_image"}, {name: "Bez kategorije", value: "no_category"},
                                {name: "Sa modelom", value: "model"}, {name: "Bez modela", value: "no_model"},
                                {name: "Sa brendom", value: "brand"}, {name: "Bez brenda", value: "no_brand"},
                                {name: "Sa barkodom", value: "ean"}, {name: "Bez barkoda", value: "no_ean"},
                            ]},
                        {name: "order", placeholder: "Sortiraj", type: "select", options: [
                                {name: "Broj pregleda", value: "clicks"},
                                {name: "Pregledi sa Eponude", value: "eclicks"},
                                {name: "Razlika (eponuda)", value: "diff"},
                                {name: "Ažurirano", value: "updated"}
                            ]},
                        {name: "break", break: true},
                        {name: "eponuda", placeholder: "Eponuda", type: "select", options: [
                                {name: "Aktivni proizvodi (u fajlu)", value: "active"},
                                {name: "Deaktivirani proizvodi (ne idu u fajl)", value: "not_active"},
                                {name: "PROIZVODI SA NAJNIZOM CENOM", value: "lowest"},
                                {name: "Listamo se, a nema nas na EP", value: "missed"},
                                {name: "Sa mapiranim EP proizvodom", value: "mapped"},
                                {name: "Nije mapiran proizvod", value: "not_mapped"},
                                {name: "Nema konkurencije", value: "alone"},
                        ]},
                        ]}
                    form={this.state.form}
                    drawer={this.props.drawer.status}
                    hook={getProducts}
                    reload={this.state.reload}
                />
            </div>
        </div>
    }
}
const mapStateToProps = state => ({
    drawer: state.drawers.products
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Products);