import React from "react";
import {connect} from "react-redux";
import {patchPath, postChatResponse} from "../../../app/hooks";
import {setReloader} from "../../../store/actions/reloadersActions";


class Response extends React.Component {

    state = {
        open: false,
        form: {
            parent: this.props.item.id,
            message: "Proizvod je dostupan i raspoloživ za poručivanje."
        }
    }

    toggle = () => {
        if(!this.state.open && !this.props.item.seen) {
            patchPath({path: `chat.${this.props.item.id}.seen`, value: 1}).then((response) => {
                this.props.setReloader("unseenChat");
                this.props.reload();
            })
        }
        this.setState({...this.state, open: !this.state.open});
    }

    submit = (e) => {
        e.preventDefault();
        if (!window.confirm("Želite da pošaljete odgovor?")) {
            return false;
        }
        postChatResponse(this.state.form).then((response) => {
            this.props.reload();
            this.setState({...this.state, open: false});
        })
    }

    render = () => {
        return <form onSubmit={this.submit}>
            {this.state.open &&
            <div className="mb-2">
                <textarea onChange={(e) => this.setState({...this.state, form: {...this.state.form, message: e.target.value}})}
                         required className="form-control" value={this.state.form.message} />
            </div>}

            <div>
                {this.state.open
                    ? <div>
                        <button className="btn btn-primary mr-2">
                            Pošaljite odgovor
                        </button>
                        <button type="button" onClick={this.toggle} className="btn btn-secondary">
                            Zatvorite
                        </button>
                    </div>
                    : <button onClick={this.toggle} className="btn btn-primary">
                        Odgovorite
                    </button>}

            </div>

        </form>
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    setReloader: setReloader
};
export default connect(mapStateToProps, mapDispatchToProps)(Response);