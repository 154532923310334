import React from "react";
import {Switch, BrowserRouter, Route} from "react-router-dom";
import Products from "../views/pages/products/Products";
import Layout from "../views/layouts/Layout";
import routes from "./routes";
import Categories from "../views/pages/categories/Categories";
import Orders from "../views/pages/orders/Orders";
import Suppliers from "../views/pages/suppliers/Suppliers";
import Dashboard from "../views/pages/dashboard/Dashboard";
import MappedCategories from "../views/pages/categories/MappedCategories";
import Login from "../views/auth/Login";
import Welcome from "../views/pages/design/Welcome";
import Live from "../views/pages/live/Live";
import Chat from "../views/pages/chat/Chat";


class App extends React.Component {

  render = () => {
    return <div>
        <BrowserRouter>
                <Switch>
                    <Route exact path={routes.login.route} component={Login} />
                </Switch>
                <Layout>
                    <Switch>
                        <Route exact path={routes.dashboard.route} component={Dashboard} />
                        <Route exact path={routes.products.route} component={Products} />
                        <Route exact path={routes.orders.route} component={Orders} />
                        <Route exact path={routes.suppliers.route} component={Suppliers} />
                        <Route exact path={routes.categories.route} component={Categories} />
                        <Route exact path={routes.mappedCategories.route} component={MappedCategories} />
                        <Route exact path={routes.welcome.route} component={Welcome} />
                        <Route exact path={routes.live.route} component={Live} />
                        <Route exact path={routes.chat.route} component={Chat} />
                    </Switch>
                </Layout>

        </BrowserRouter>
    </div>
  }
}
export default App;