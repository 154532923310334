import React from "react";
import {openDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";
import {getMappedCategories} from "../../../app/hooks";
import MappedCategoryItem from "./MappedCategoryItem";


class UnmappedCategories extends React.Component {

    state = {
        categories: [],
        form: {
            unmapped: true,
            withSupplier: true,
            all: true
        }
    }

    render = () => {
        if(this.state.categories.length === 0)    {
            return "";
        }
        return <div className="shadow bg-white">
            <h5 className="p-3 border-bottom">Nemapirane kategorije ({this.state.categories.length})</h5>
            <div>
                {this.state.categories.map((item) => {
                    return <MappedCategoryItem key={item.id} item={item} reload={this.load} />
                })}
            </div>
        </div>
    }

    load = (form) => {
        if(!form) form = this.state.form;
        getMappedCategories(form).then((response) => {
            this.setState({...this.state, categories: response.data});
        })
    }
    componentDidMount() {
        this.load();
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(UnmappedCategories);