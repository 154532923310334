import React from 'react';
import Overlay from "./Overlay";


class Drawer extends React.Component   {

    state = {
        render: false
    }

    render = () => {
        let classes = this.props.show ? "drawer show bg-light" : "drawer bg-light";
        if(this.props.anchor === "left")    {
            classes = classes + " left";
        }
        if(this.props.anchor === "bottom")    {
            classes = classes + " bottom";
        }
        if(this.props.anchor === "top")    {
            classes = classes + " top";
        }
        let style = {};
        if(this.props.width)        {
            style.width = this.props.width;
        }
        if(this.props.zIndex)        {
            style.zIndex = this.props.zIndex;
        }
        if(this.props.maxWidth)        {
            style.maxWidth = this.props.maxWidth;
        } else      {
            style.maxWidth = "90%";
        }
        return <React.Fragment>
                <div className={classes} style={style}>
                   <div className="drawer_scrollable">
                        <button onClick={this.props.close}
                            className="btn btn-info position-absolute" style={{top: 10, right: 10, zIndex: 15}}>
                            <i className="fa fa-close"/></button>
                        {this.state.render &&
                        this.props.children}
                    </div>
                </div>

            {!this.props.noOverlay &&
            <Overlay show={this.props.show} close={this.props.close} zIndex={this.props.zIndex ? this.props.zIndex - 1 : false} />}
        </React.Fragment>
    };

    escFunction = (e) =>  {
        if(e.keyCode === 27) {
            this.props.close();
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
        if(this.props.preload)      {
            this.setState({...this.state, render: true});
        }
    }

    componentWillUnmount() {
        document.body.style.overflow = "auto";
        document.removeEventListener("keydown", this.escFunction, false);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.show !== this.props.show)  {
            if(this.props.show)     {
                document.body.style.overflow = "hidden";
                this.setState({...this.state, render: true});
            }
            else {
                document.body.style.overflow = "auto";
                if(this.props.reload)       {
                    let t = this;
                    setTimeout(function () {
                        t.setState({...t.state, render: false});
                    }, 300);

                }
            }
        }
    }
}

export default Drawer;
