import React from "react";
import {connect} from "react-redux";
import axios from "axios";
import {updater} from "../../app/helpers";


class ExcelUpload extends React.Component {

    state = {
        uploadProgress: false,
        file: "",
        error: false,
        limit: ""
    }

    submit = () => {
        if(!this.state.file)    {
            alert("Nije unet fajl.");
            return false;
        }
        const formData = new FormData();
        formData.append("file", this.state.file, this.state.file.name);
        formData.append("filename", this.state.file.name);
        formData.append("supplierId", this.props.supplierId);
        if(this.state.limit)    {
            formData.append("limit", this.state.limit);
        }

        this.setState({...this.state, error: false, uploadProgress: false});
        axios({
            method: "PUT",
            url: updater( "excel"),
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: progressEvent => {
                let previewProcess = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                if(previewProcess >= 100)  {
                    previewProcess = false;
                }
                this.setState({...this.state, uploadProgress: previewProcess});
            },
        }).then((response) => {
            this.setState({...this.state, uploadProgress: false});
            setTimeout(() => {
                this.props.reload();
            }, 2000);
        }).catch((error) => {
            this.setState({...this.state, uploadProgress: false, error: JSON.stringify(error)});
        });
    }

    changeFile = (e) => {
        let file = e.target.files[0];
        this.setState({...this.state, file: file});
    };

    render = () => {
        return <div>
            <h5 className="mb-3">EXCEL fajl upload</h5>

           <input type="file" name="file" onChange={this.changeFile} required />
            <div className="mt-3 mb-3">
                Broj proizvoda za unos: <span className="text-black-50">(ostavite prazno za sve proizvode)</span>
                <input type="number" placeholder="limit" style={{width: 200}}
                       onChange={(e) => this.setState({...this.state, limit: e.target.value})}
                       value={this.state.limit} className="form-control" />
            </div>
           <button onClick={this.submit} className="btn btn-primary mr-2">Pokreni Azuriranje Fajla</button>


            {this.state.uploadProgress &&
            <div className="mt-3">
                <h5 className="text-center mb-2">
                    {this.state.uploadProgress}%
                </h5>
                <div className="bg-primary" style={{width: `${this.state.uploadProgress}%`, height: 5}} />
            </div>}
            {this.state.error &&
            <div className="alert alert-danger mt-2"> {this.state.error} </div>}
        </div>

    }

}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(ExcelUpload);