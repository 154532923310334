import React from 'react';
import {connect} from "react-redux";
import Loader from "../framework/Loader";
import {getSuppliers} from "../../app/hooks";
import Reports from "../elements/Reports";
import SuppliersCategories from "../pages/dashboard/SuppliersCategories";


class SupplierDrawer extends React.Component {

    state = {
        supplier: false,
        updater: false
    }

    render() {
        if(!this.state.supplier)     {
            return <Loader />
        }
        const supplier = this.state.supplier;
        return (
            <div className="p-3">
                <h4 className="font-weight-bold mb-4">
                    {supplier.name}
                    {supplier.updater &&
                    <button onClick={() =>this.setState({...this.state, updater: !this.state.updater})}
                        className="btn btn-primary ml-3"> Podaci za azuriranje </button>}
                </h4>

                {supplier.updater && this.state.updater &&
                <div className="bg-white shadow p-3 mb-4 prewrap">{supplier.updater}</div>}

                <div className="row">
                    <div className="col-md-5">
                        {(supplier.website || supplier.description) &&
                        <div className="bg-white shadow p-3 mb-4">
                            {supplier.website &&
                            <div className="mb-3"> {supplier.website} </div>}
                            {supplier.description &&
                            <div className="prewrap"> {supplier.description} </div>}
                        </div>}

                        <Reports supplier={supplier} />
                    </div>
                    <div className="col-md-7">
                        <SuppliersCategories supplierId={supplier.id}  />
                    </div>
                </div>
            </div>

        );
    }

    load = () => {
        getSuppliers({id: this.props.drawer.id}).then((response) => {
            this.setState({...this.state, supplier: response.data})
        })
    }

    componentDidMount() {
        this.load();
    }
}
const mapStateToProps = state => ({
    drawer: state.drawers.supplier
});
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(SupplierDrawer);
