import React from "react";
import {connect} from "react-redux";
import {
    deleteBlock,
    patchPath,
    putCarouselProducts,
    patchCarouselProductsOrder
} from "../../../app/hooks";
import Input from "../../framework/form/Input";
import Banners from "../../elements/Banners";
import {image} from "../../../app/helpers";
import Checkbox from "../../framework/form/Checkbox";
import Select from "react-select";
import {openDrawer} from "../../../store/actions/drawersActions";
import CarouselProduct from "./CarouselProduct";
import Nestable from "react-nestable";
import DateTimePicker from "../../framework/form/DateTimePicker";
import {fromNow} from "../../../providers/time";


class Block extends React.Component {

    state = {
        open: false
    }

    deleteBlock = () => {
        if (!window.confirm("Želite da obrišete blok?")) {
            return false;
        }
        deleteBlock({id: this.props.item.id}).then((response) => {
            this.props.reload();
        });
    }

    patch = (field, value) => {
        patchPath({path: `blocks.${this.props.item.id}.${field}`, value: value}).then((response) => {
            this.props.reload();
        });
    }

    addProducts = (products) => {
        putCarouselProducts({id: this.props.item.id, ids: products}).then((response) => {
            this.props.reload();
        })
    }
    changedCarouselProductsOrder = (items) => {
        patchCarouselProductsOrder({items: items});
    }

    render = () => {
        const item = this.props.item;
        return <div className="shadow bg-white p-2">
            {item.carousel_name && !item.carousel_display &&
            <div className="alert alert-danger font-weight-bold font_09 mb-2 p-1">
                Carousel se ne prikazuje (morate čekirati jednu od opcija)
            </div> }
            <div className="d-flex align-items-center">
                {this.props.handler}
                <div className="ml-2 flex-grow-1 d-flex justify-content-between">

                    <div className="form-inline">
                        <Input
                            key={item.id}
                            placeholder="Unesite naziv bloka."
                            submit={true}
                            nullable={true}
                            name="alt"
                            path={`blocks.${item.id}.title`}
                            value={item.title ? item.title : ""} />
                        {item.banner_name &&
                        item.banners.map((banner) => {
                            return <img key={banner.id} src={image(banner.image_path, false, banner.image_bucket)} width="30px" alt="slider" className="ml-2" />
                        })}

                        <span className="ml-3 font_09">
                            {item.countdown_start &&
                            <span className="d-block">Počinje: <strong>{fromNow(item.countdown_start)}</strong></span>}
                            {item.countdown_end &&
                            <span className="d-block">Završava se: <strong>{fromNow(item.countdown_end)}</strong></span>}
                        </span>

                    </div>

                    <div className="">
                        <span className="mr-2">
                            <Checkbox name="special" path={`blocks.${item.id}.special`} value={1}
                                      checked={!!item.special}
                                      title="Specijalan blok" true="ISTAKNITE" false="ISTAKNITE" />
                        </span>
                        <button onClick={this.deleteBlock} className="btn btn-danger btn-sm mr-2">Obrišite</button>
                        <button onClick={() => this.setState({...this.state, open: !this.state.open})}
                                className="btn btn-secondary btn-sm">
                            <i className="fa fa-chevron-down" />  Podešavanja
                        </button>
                    </div>
                </div>
            </div>
            {this.state.open &&
            <div className="border-top mt-2">
                <div className="mb-3 p-3 bg-light">
                    <div className="font-weight-bold">
                        VREME BLOKA:
                    </div>
                    <div className="form-inline">
                        <DateTimePicker
                            placeholder="Počinje"
                            path={`blocks.${item.id}.countdown_start`}
                            value={item.countdown_start}
                            name={`dateTimePicker_${item.id}_start`} />
                        &nbsp;  &nbsp;
                        <DateTimePicker
                            placeholder="Završava se"
                            path={`blocks.${item.id}.countdown_end`}
                            value={item.countdown_end}
                            name={`dateTimePicker_${item.id}_end`} />
                    </div>

                </div>

                {item.banner_name &&
                <Banners
                    open={true}
                    name={item.banner_name}
                    reload={this.props.reload}
                    banners={item.banners} />}

                {item.carousel_name &&
                <table className="table">
                    <tbody>
                        <tr>
                            <td width={40}>
                                <Checkbox
                                    value={1}
                                    checked={item.carousel_display === 1}
                                    nullable={true}
                                    path={`blocks.${item.id}.carousel_display`}
                                    onChange={this.props.reload}
                                />
                            </td>
                            <td>Praćene kategorije
                                <div className="text-black-50 font_09">
                                U karuselu se prikazuju proizvodi kategorija koje je korisnik najviše gledao na sajtu.
                            </div> </td>
                        </tr>
                    <tr>
                        <td>
                            <Checkbox
                                value={2}
                                nullable={true}
                                checked={item.carousel_display === 2}
                                path={`blocks.${item.id}.carousel_display`}
                                onChange={this.props.reload} />
                        </td>
                        <td>
                            <div className="">
                                <div className="mb-2">
                                    Postavite kategoriju.
                                    <div className="text-black-50 font_09">
                                        Karusel sa proizvodima iz odabrane kategorije. Ako je postavljena cena,
                                        prikazvace se proizvodi priblizne cene.
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    Kategorija: <div style={{width: 260}} className="mr-4 ml-2">
                                        <Select
                                        value={this.props.categories.find(option => option.id === item.category_id)}
                                        isClearable={true}
                                        placeholder="Kategorije"
                                        onChange={(item) => this.patch("category_id", item?.id)}
                                        options={this.props.categories}
                                        getOptionLabel={option => option.name}
                                        getOptionValue={option => option.id} />
                                    </div>
                                    <div className="mr-2 text-nowrap">
                                        Postavite prosečnu cenu:
                                    </div>
                                    <div style={{width: 180}}>
                                        <Input
                                            submit={true}
                                            price={true}
                                            nullable={true}
                                            name="category_price"
                                            path={`blocks.${item.id}.category_price`}
                                            value={item.category_price ? item.category_price : ""}
                                        />
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Checkbox
                                value={3}
                                checked={item.carousel_display === 3}
                                nullable={true}
                                path={`blocks.${item.id}.carousel_display`}
                                onChange={this.props.reload} />
                        </td>
                        <td>
                            <button onClick={() => this.props.openDrawer({name: "products", callback: this.addProducts})}
                                className="btn btn-primary btn-sm">
                                Dodajte proizvode
                            </button>
                            <div className="mt-3">
                                <Nestable
                                    maxDepth={1}
                                    onChange={this.changedCarouselProductsOrder}
                                    handler={<span className="nestable_collapse_icon">
                                        Premestite:  <i className="fa fa-arrows" />
                                    </span>}
                                    items={item.carousel}
                                    renderItem={(props) => <CarouselProduct {...props} reload={this.props.reload} />} />
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>}
            </div>}
        </div>
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Block);