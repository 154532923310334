import React from "react";
import Table from "../../framework/table/Table";
import {getSuppliers} from "../../../app/hooks";
import {openDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";
import AddSupplier from "./AddSupplier";


class Suppliers extends React.Component {

    state = {
        reload: false
    }

    renderInfo = (item) => {
        return <div>
            <div>
                <a href="/" className="font-weight-bold"
                   onClick={(e) => this.props.openDrawer({e: e, name: "supplier", id: item.id})}>
                    <h5> {item.name} </h5>
                </a>
                <div>
                    <div className="row m-0">
                        <div className="col-12">
                            <a href="/" className="inherit" onClick={(e) =>
                                this.props.openDrawer({e: e, name: "products",
                                    form: {supplier: item.id + ""}})}>
                                Ukupno proizvoda: <b className="text-primary"> {item.total} </b>
                            </a>
                        </div>
                        <div className="col-md-4">
                            <div>
                                <a className="inherit" href="/" onClick={(e) =>
                                    this.props.openDrawer({e: e, name: "products",
                                        form: {state: "active", supplier: item.id + ""}})}>
                                    Aktivirani: <b className="text-primary">{item.activated}</b>
                                </a>
                          </div>
                            <div>
                                <a className="inherit" href="/" onClick={(e) =>
                                    this.props.openDrawer({e: e, name: "products",
                                        form: {state: "no_active", supplier: item.id + ""}})}>
                                    Deaktivirani: <b className="text-primary">{item.deactivated}</b>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div>
                                <a className="inherit" href="/" onClick={(e) =>
                                    this.props.openDrawer({e: e, name: "products",
                                        form: {state: "state", supplier: item.id + ""}})}>
                                Na stanju: <b className="text-primary">{item.state}</b>
                                </a>
                            </div>
                            <div>
                                <a className="inherit" href="/" onClick={(e) =>
                                    this.props.openDrawer({e: e, name: "products",
                                        form: {state: "no_state", supplier: item.id + ""}})}>
                                    Nema na stanju: <b className="text-primary">{item.nostate}</b>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div>
                                <a className="inherit" href="/" onClick={(e) =>
                                    this.props.openDrawer({e: e, name: "products",
                                        form: {state: "supply", supplier: item.id + ""}})}>
                                Nije rasprodato: <b className="text-primary">{item.supply}</b>
                                </a>
                            </div>
                            <div>
                                <a className="inherit" href="/" onClick={(e) =>
                                    this.props.openDrawer({e: e, name: "products",
                                        form: {state: "no_supply", supplier: item.id + ""}})}>
                                Rasprodato: <b className="text-primary">{item.nosupply}</b>
                                </a>
                            </div>
                            <div>
                                <a className="inherit" href="/" onClick={(e) =>
                                    this.props.openDrawer({e: e, name: "products",
                                        form: {state: "deleted", supplier: item.id + ""}})}>
                                Obrisani: <b className="text-primary">{item.deleted}</b>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    }

    render = () => {
        return <div>
            <div className="container-fluid">
                <div>
                    <AddSupplier reload={() => this.setState({...this.state, reload: !this.state.reload})} />
                </div>
                <Table
                    columns={[
                        {field: "info", name: "Informacije", render: this.renderInfo},
                    ]}
                    form={{limit: 20, withCounts: true}}
                    hook={getSuppliers} reload={this.state.reload}
                />
            </div>
        </div>
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Suppliers);