import React from "react";
import {openDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";
import Reports from "../../elements/Reports";
import UnmappedCategories from "./UnmappedCategories";
import DeactivatedProducts from "./DeactivatedProducts";
import SuppliersCategories from "./SuppliersCategories";
import {getSetting, setSetting} from "../../../providers/settings";
import ActiveOrders from "./ActiveOrders";
import EponudaLogs from "./EponudaLogs";
import ProductMessages from "./ProductMessages";


class Dashboard extends React.Component {

    state = {
        loaded: false,
        tab1: 1,
    }

    setTab = (tab, value) => {
        this.setState({...this.state, [tab]: value});
        setSetting(tab, value);
    }

    render = () => {
        if(!this.state.loaded)  return "";
        return <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-5">

                        <div className="btn-group mb-3" role="group">
                            {[
                                {name: "Ažuriranje", value: 1},
                                {name: "Aktivne porudžbine", value: 2},
                                {name: "Eponuda", value: 3},
                                ].map((item) => {
                                return  <button key={item.value} type="button"
                                                onClick={() => this.setTab("tab1", item.value)}
                                            className={this.state.tab1 === item.value ? "btn btn-primary" : "btn btn-white"}>
                                    {item.name}
                                </button>
                            })}
                        </div>

                        {this.state.tab1 === 1 &&
                        <Reports />}
                        {this.state.tab1 === 2 &&
                        <ActiveOrders />}
                        {this.state.tab1 === 3 &&
                        <div className="bg-white shadow p-3">
                            <div className="mb-3">
                                <a href="http://server.gogorila.com/eponuda/MdCrfbORHMvfvYoE8Szn?admin=true" target="_blank" rel="noreferrer">
                                    Eponuda link
                                </a>
                            </div>
                            <EponudaLogs />
                        </div>}
                    </div>
                    <div className="col-md-7">
                        <div className="mb-4" style={{maxHeight: 500, overflowY: "auto"}}>
                            <UnmappedCategories />
                        </div>
                        <div className="mb-4" style={{maxHeight: 800, overflowY: "auto"}}>
                            <SuppliersCategories />
                        </div>
                        <div className="mb-4" style={{maxHeight: 800, overflowY: "auto"}}>
                            <ProductMessages />
                        </div>
                        <div className="mb-4" style={{maxHeight: 800, overflowY: "auto", overflowX: "hidden"}}>
                            <DeactivatedProducts />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    componentDidMount() {
        const tab1 = getSetting("tab1", 1);
        this.setState({...this.state, tab1: tab1, loaded: true});
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);