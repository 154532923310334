import React from "react";
import {connect} from "react-redux";
import {getProducts, patchPath} from "../../app/hooks";
import Table from "../framework/table/Table";
import {formatPrice, processPrice} from "../../providers/price";
import Empty from "../framework/Empty";
import {fromNow} from "../../providers/time";
import {eimage, image} from "../../app/helpers";
import Model from "../pages/products/Model";


class ProductsTransferDrawer extends React.Component {

    state = {
        reload: false,
        category1: false,
        category2: false,
        form1: {withCategory: true, supplier: "", category: ""},
        form2: {withCategory: true, supplier: "", category: ""},
    }

    editField = (e, category) => {
        const name = e.target.name;
        const value = e.target.value;
        if(name === "category") {
            this.setState({...this.state, [category]: value});
        }
    }

    transfer = (item, to) => {
        const category = this.state[to];
        if(!category)   {
            alert("Nije postavljena kategorija.");
            return false;
        }
        patchPath({path: `products.${item.id}.category_id`, value: category}).then((response) => {
            this.setState({...this.state, reload: !this.state.reload});
        })
    }

    renderPrice = (item) => {
        const price = processPrice(item);
        const pdv = (item.pdv === 0) ? "0" : item.pdv;
        const margo = (item.margo === 0) ? "0" : item.margo;
        return <div>

            <table className="table table-sm table-bordered text-center text-nowrap mb-1">
                <tbody>
                <tr>
                    <td title="Cena od dobavljaca (u fajlu, sistem azurira).">
                        Kupovna <br /> {formatPrice(item.purchase_price)}
                    </td>
                    <td title={`PDV na kupovnu cenu.`}>
                        Pdv <br /> {pdv || <span className="text-danger">prazno</span>}
                    </td>
                    <td title={`MARZA za kupovnu cenu."`}>
                        Marža <br /> {margo || <span className="text-danger">prazno</span>}
                    </td>
                    <td title="Kupovna cena + PDV + MARŽA">
                        Izračunata <br />
                        <span className={price === item.calculated_price ? "text-success font-weight-bold" : ""}>
                            {formatPrice(item.calculated_price)}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td title="Najniza cena na Eponudi.">
                        Eponuda <br /> {item.min_price ? formatPrice(item.min_price) : <Empty />}
                    </td>
                    <td colSpan="2" title={`MARZA u dinarima ispod koje se ne ide."`}>
                        Min marža <br /> {item.min_margo ? formatPrice(item.min_margo) : <Empty />}
                        {item.min_margo && item.pdv &&
                        <div title="Minimum cena ispod koje se ne ide: kupovna cena x pdv + min marža)">
                            {formatPrice((item.purchase_price * (1 + parseInt(item.pdv) / 100)) + parseFloat(item.min_margo))}
                        </div>}
                    </td>
                    <td title="Cenu postavlja sistem analizom ostalih cena.">
                        SISTEM <br />  {item.recommended_price
                        ? <span className={price === item.recommended_price ? "text-success font-weight-bold" : ""}>
                                     {formatPrice(item.recommended_price)}
                                </span>
                        : <Empty/>}
                    </td>
                </tr>
                </tbody>
            </table>

            {item.min_price &&
            <div className="mb-1" title="Razlika cena na Eponudi i trenutna cena na sajtu.">
                Razlika (eponuda): &nbsp;
                <span style={{fontSize: 18, fontWeight: "bold"}} className={(processPrice(item) - item.min_price) <= 0 ? "text-success" : "text-danger"}>
                    {formatPrice(processPrice(item) - item.min_price)}
                </span>
            </div>}

            {item.recommended_price &&
            <div title="Razlika izmedju cene koju kreira sistem i izračunate cene (kupovna + pdv + marža)">
                Razlika (marža): <b>{formatPrice(item.recommended_price - item.calculated_price)}</b>
            </div>}

            <div className="mt-1" title="Cena koju postavlja admin. Overriduje sve ostale cene.">
                Admin cena: {item.admin_price
                ? <span className={price === item.admin_price ? "text-success font-weight-bold" : ""}>
                    {formatPrice(item.admin_price)}
                </span>
                : <Empty/>}
            </div>
            {item.admin_discount_price &&
            <div>
                Cena pre popusta: <del>{formatPrice(item.admin_discount_price)}</del>
            </div>}

            {item.admin_price &&
            <>
                <div title="Početak ADMIN CENE. Ako nije postavljeno, cena je aktivna posle postavljanja.">
                    Početak: {item.countdown_start ? fromNow(item.countdown_start) : <Empty/>}
                </div>
                <div title="Kraj ADMIN CENE. Ako nije postavljeno, cena ne ističe.">
                    Kraj: {item.countdown_end ? fromNow(item.countdown_end) : <Empty/>}
                </div>
            </>}
        </div>
    }

    renderInfo = (item) => {
        return <div>
            <div className="d-flex align-items-center w-100">
                <div className="mr-3" style={{width: 80}}>
                    <img src={image(item.image_path)} alt={item.name} className="img-fluid"/>
                    <div>
                        <button
                            className="btn btn-primary btn-sm pl-1 pr-1 mt-2"
                            onClick={() => this.props.openDrawer({name: "product", id: item.id, onClose: this.reload})}>
                            Pogledajte
                        </button>
                    </div>
                </div>
                <div className="flex-grow-1">
                    <Model product={item} reload={() => this.setState({...this.state, reload: !this.state.reload})} />
                    <div>Kategorija: {item.category?.name}</div>
                    <div>Model: <Empty value={item.model}/></div>
                    <div>Brend: <Empty value={item.brand}/></div>
                    <div>Barkod: <Empty value={item.ean}/></div>
                    <div>Dobavljač:
                        <a href="/" onClick={(e) => this.props.openDrawer({e: e, name: "supplier", id: item.supplier?.id})} className="ml-2">
                            <b>{item.supplier?.name} </b>
                        </a>
                    </div>
                </div>
            </div>
            {item.eponuda_link &&
            <div className="border-top mt-1 pt-1 bg-light position-relative">
                <button
                    onClick={() => this.deleteModel(item.id)}
                    className="btn btn-light btn-sm position-absolute" style={{top: 0, right: 0}}><i className="fa fa-close" /></button>
                <div className="d-flex align-items-center">
                    <div className="mr-3 text-center" style={{width: 80}}>
                        <img src={eimage(item.eponuda_image_name, false, "eponuda")} alt={item.eponuda_name} className="img-fluid"/>
                    </div>
                    <div>
                        {item.eponuda_name &&
                        <div title="Ime proizvoda na Eponudi.">
                            {item.eponuda_name}
                        </div>}
                        <div title="Link ka proizvodu na Eponudi.">
                            <a href={"https://eponuda.com" + item.eponuda_link} rel="noreferrer" target="_blank">
                                {"https://eponuda.com" + item.eponuda_link}
                            </a>
                        </div>
                        <div className="d-flex">
                            <div className="mr-3" title="Najniza cena na Eponudi.">
                                Min cena: {item.min_price ? formatPrice(item.min_price) : <Empty />}
                            </div>
                            {item.max_price &&
                            <div className="mr-3" title="Najvisa cena na Eponudi.">
                                Max cena: {item.max_price ? formatPrice(item.max_price) : <Empty />}
                            </div>}
                            {item.gogorila_price &&
                            <div className="mr-3" title="GoGOrila cena na Eponudi.">
                                GoGorila cena: {item.gogorila_price ? formatPrice(item.gogorila_price) : <Empty />}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>}

        </div>
    }

    renderTransfer = (item, to) => {
        return <div className="">
            <button onClick={() => this.transfer(item, to)}
                className="btn btn-sm btn-primary">
                <i className="fas fa-exchange-alt" /><br /> PREBACITE</button>
        </div>
    }

    render = () => {
        return <div>
            <div className="row m-3">
                <div className="col">
                    <Table
                        columns={[
                            {field: "price", name: "Cena", width: 260, render: this.renderPrice},
                            {field: "name", name: "Informacije", render: this.renderInfo},
                            {field: "transfer", name: "Prebacite", render: (item) => this.renderTransfer(item, "category2")},
                        ]}
                        filters={[{name: "supplier", type: "supplier"},
                            {name: "category", type: "category"},
                        ]}
                        form={this.state.form1}
                        editField={(e) => this.editField(e, "category1")}
                        hook={getProducts}
                        noHistory={true}
                        reload={this.state.reload}
                    />
                </div>
                <div className="col">
                    <Table
                        columns={[
                            {field: "transfer", name: "Prebacite", render: (item) => this.renderTransfer(item, "category1")},
                            {field: "price", name: "Cena", width: 260, render: this.renderPrice},
                            {field: "name", name: "Informacije", render: this.renderInfo},
                        ]}
                        filters={[{name: "supplier", type: "supplier"},
                            {name: "category", type: "category"},
                        ]}
                        form={this.state.form2}
                        hook={getProducts}
                        noHistory={true}
                        editField={(e) => this.editField(e, "category2")}
                        reload={this.state.reload}
                    />
                </div>
            </div>
        </div>
    };
}
const mapStateToProps = state => ({
    drawer: state.drawers.productsTransfer
});
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductsTransferDrawer);
