import React from "react";
import {openDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";
import {patchMappedCategories} from "../../../app/hooks";


class MappedCategoryItem extends React.Component {

    state = {
        openProducts: false
    }

    ignore = (ignore) => {
        if(!window.confirm(`Zelite da ${ignore === 1 ? "ignorišete" : "vratite"} kategoriju?`))  {
            return false;
        }
        patchMappedCategories({id: this.props.item.id, ignore: ignore}).then((response) => {
            this.props.reload();
        });
    }

    setCategory = (category) => {
        patchMappedCategories({id: this.props.item.id, categoryId: category.id}).then((response) => {
            this.props.reload();
        });
    }

    toggleProducts = (e) => {
        e.preventDefault();
        this.setState({...this.state, openProducts: !this.state.openProducts});
    }

    render = () => {
        const item = this.props.item;
        const products = JSON.parse(item.products);
        return <div key={item.id} className="p-1 pl-3 pr-3 border-bottom" style={{opacity: item.ignored ? 0.3 : 1}}>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <a href="/" onClick={(e) => this.props.openDrawer({e: e, name: "supplier", id: item.supplier.id})}>
                        {item.supplier?.name}
                    </a> :  {item.name}

                    {item.category?.name && <div> mapirano: <b> {item.category.name} </b></div>}
                </div>
                <div>
                    <a href="/" onClick={this.toggleProducts}
                       className="ml-2">proizvodi: {products?.length}</a>
                    <button onClick={() => this.props.openDrawer({name: "categories", callback: this.setCategory})}
                            className="btn-primary btn-sm btn ml-2 mr-1">Kategorije</button>
                    <button onClick={() => this.ignore(item.ignored ? "vrati" : 1)}
                            className="btn-white btn-sm btn">{item.ignored ? "Vrati" : "Ignoriši"}</button>
                </div>
            </div>
            {this.state.openProducts &&
            products.map((item, i) => {
                return <div key={i}>
                    {item}
                </div>
            })}
        </div>
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(MappedCategoryItem);