import React from "react";
import {connect} from "react-redux";
import Loader from "../framework/Loader";
import {formatDateTime} from "../../providers/time";
import {getReports} from "../../app/hooks";
import {openDrawer} from "../../store/actions/drawersActions";


class ReportItem extends React.Component {

    state = {
        showErrors: false,
        errors: []
    }

    errors = () => {
        if(!this.state.showErrors)  {
            getReports({id: this.props.item.id, withErrors: true}).then((response) => {
                this.setState({...this.state, errors: response.data.errors});
            });
        }
        this.setState({...this.state, showErrors: !this.state.showErrors});
    }

    render = () => {
        const item = this.props.item;
        return <div className="border-bottom p-2">
            <div className="d-flex align-items-center">
                {item.finished_at &&
                <div className="mr-3">
                    {item.status === "success"
                        ? <i className="fa fa-check-circle green font_2" />
                        : <i className="fa fa-warning red font_2" />}
                </div>}

                <div className="flex-grow-1">
                    <div className="font-weight-bold">
                        <a href="/" onClick={(e) => this.props.openDrawer({e: e, name: "supplier", id: item.supplier.id})}>
                            {item.supplier.name}
                        </a>
                        <span className="ml-3 prewrap">{item.report}</span>
                        {item.updated !== null &&
                        <span title="Broj ažuriranih proizvoda." className="text-black-50 ml-2 font-weight-normal">
                            ({item.updated})
                        </span>}
                        {item.errors.length > 0 &&
                        <span className="ml-2 text-black-50" title="Neuneti proizvodi zbog greske. (logovi)">
                              <button onClick={this.errors}
                                  className="btn btn-light">
                                   {item.errors.length}
                              </button>
                        </span>}
                    </div>
                    <div>
                        Započeto: {formatDateTime(item.started_at)}
                    </div>
                    {item.finished_at
                        ? <div>Završeno: {formatDateTime(item.finished_at)}</div>
                        : <div className="text-black-50 font-italic">Nije završeno </div>}
                </div>
                <div>
                    {!item.finished_at &&
                    <Loader size="sm" />}
                </div>
            </div>
            {this.state.showErrors &&
            <div className="w-100 p-3">
                {this.state.errors.map((item) => {
                    return <div key={item.id} className="border-bottom pb-1 mb-1">
                        <div className="mb-2">
                            {item.code} : {item.name}
                        </div>
                        {item.error}
                    </div>
                })}
            </div>}
        </div>

    }

}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportItem);