import React from "react";
import {putOrderHistory} from "../../../app/hooks";


class OrderNote extends React.Component {

    state = {
        open: false,
        note: ""
    }

    submit = () => {
        let note = this.state.note;
        putOrderHistory({id: this.props.id, note: note}).then((response) => {
            this.props.reload();
            this.setState({...this.state, note: "", open: false});
        });
    };

    render = () => {
        return  <div>

            {this.state.open
                ? <div>
                    <textarea
                        placeholder="Unesite komentar..."
                        className="w-100"
                        onChange={(e) => this.setState({...this.state, note: e.target.value})}
                        value={this.state.note} />

                    <button onClick={this.submit} className="mr-2 btn btn-success btn-sm">
                        Dodaj
                    </button>

                    <button onClick={() => this.setState({...this.state, open: false})}
                            className="btn btn-secondary btn-sm">
                        Otkaži
                    </button>

                </div>
                : <div>
                    <button className="btn btn-secondary btn-sm" onClick={() => this.setState({...this.state, open: true})}>
                        {this.props.note ? "Prepravite" : "Dodajte"} komentar
                    </button>
                </div>}
        </div>
    };

}

export default OrderNote;
