import React from "react";
import {openDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";
import {image} from "../../../app/helpers";


class Live extends React.Component {

    first = () => {
        const holder = [];
        for(let key in this.props.live) {
            if(!this.props.live[key].data)  {
                return;
            }
            holder.push(<tr key={key} style={{verticalAlign: "middle"}}>
                <td style={{verticalAlign: "middle"}}>{key}</td>
                <td>
                    <div className="d-flex flex-wrap">
                        {this.props.live[key].data.map((data, i) => {
                            return <div key={i} className="border p-1 mr-1 overflow-hidden mb-1" style={{maxWidth: 100}}>
                                <a href={data.id} target="_blank" rel="noreferrer">
                                    {data.type === "page" &&
                                    <div className="text-center">
                                        <div className="text-black-50 font_08">strana</div>
                                        <div className="font_09">{data.title}</div>
                                    </div>}

                                    {data.type === "category" &&
                                    <div className="text-center">
                                        <div className="text-black-50 font_08">kategorija</div>
                                        <div className="font_09">{data.title}</div>
                                    </div>}

                                    {data.type === "product" &&
                                    <div className="text-center">
                                        <div className="text-black-50 font_08">proizvod</div>
                                        <div title={data.title} className="font_09">
                                            <img src={image(data.image)} width={50} alt={data.title} />
                                        </div>
                                    </div>}
                                </a>
                            </div>
                        })}
                    </div>
                </td>
            </tr>);
        }
        return holder;
    }

    render = () => {
        if(Object.values(this.props.live).length === 0) {
            return <div className="font-weight-bold text-black-50">Nema korisnika na sajtu.</div>
        }
        return <div>
            <div className="container-fluid">
                <table className="table table-sm bg-white shadow">
                    <thead>
                        <tr>
                            <td style={{width: 100}}>Session</td>
                            <td className="text-center">History</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.first()}
                    </tbody>
                </table>
            </div>
        </div>
    }
}
const mapStateToProps = state => ({
    live: state.live
});
const mapDispatchToProps = {
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Live);