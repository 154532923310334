import React from "react";
import {closeDrawer} from "../../store/actions/drawersActions";
import {connect} from "react-redux";
import {randString, server} from "../../app/helpers";
import axios from "axios";



class MailerDrawer extends React.Component {

    state = {
        form: {
            to: this.props.drawer.to || "",
            subject: this.props.drawer.subject || "GoGorila Tim",
            body: `Poštovani, \n\n${this.props.drawer.body || ""} \n\nOva poruka je automatska. Ukoliko Vam je potrebna neka dodatna informacija ili kod pošiljke možete nas kontaktirati na telefon 064/860-15-54 ili na e-mail: web@gogorila.com \n\nS' poštovanjem, \nGoGorila tim`,
            attachments: this.props.drawer.attachments ? "true" : "false",
            file: ""
        },
        sent: false,
        loading: false

    };

    changeField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let newState = {...this.state, form: {...this.state.form, [name]: value}};
        this.setState(newState);
    };

    addFile = (e) => {
        if (e.target.files.length === 0) {
            alert('Niste uneli fajl.');
            return false;
        }
        let newState = {...this.state, form: {...this.state.form, file: e.target.files[0]}};
        this.setState(newState);
    }

    email = () => {
        var r = window.confirm("Želite da pošaljete email?");
        if (r !== true) {
            return false;
        }
        this.setState({...this.state, sent: false, loading: true});
        var formData = new FormData();
        for (let key in this.state.form) {
            if(key === "file" && this.state.form[key] !== "")  {
                formData.append(key, this.state.form[key], this.state.form[key].name);
            } else  {
                formData.append(key, this.state.form[key]);
            }
        }
        axios({
            method: 'POST',
            url: server("mailer"),
            data: formData,
            onUploadProgress: progressEvent => {
                let previewProcess = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({...this.state, loader: previewProcess});
                if(previewProcess === 100 && this.state.preload)  {

                }
            }
        }).then((response) => {
            this.setState({...this.state, sent: true, loading: false});
            if(this.props.drawer.callback) {
                this.props.drawer.callback(this.state.form.body);
            }
            setTimeout(() => {
                this.props.closeDrawer("mailer");
            }, 2000);
        }).catch((error) => {
            alert(error)
        });
    };

    render = () => {
        return <div className="p-3 pt-5">
            <div>
                <div className="font-weight-bold">To:</div>
                <input
                    name="to"
                    onChange={this.changeField}
                    className="form-control"
                    value={this.state.form.to || ""} />

                <div className="font-weight-bold mt-3">Subject:</div>
                <input
                    name="subject"
                    onChange={this.changeField}
                    className="form-control"
                    value={this.state.form.subject} />

                <div className="font-weight-bold mt-3">Poruka:</div>
                <textarea
                    name="body"
                    onChange={this.changeField}
                    className="form-control w-100"
                    rows={10}
                    value={this.state.form.body} />

                {this.state.form.attachments === "true" &&
                <div className="mt-3">
                    {[
                        {name: "obrazac za odustajanje", link: server("Obrazac_za_odustanak_od_ugovora_na_daljinu.pdf")},
                        {name: "ugovor o prodaji", link: server("Ugovor_o_prodaji.pdf")},
                        {name: "reklamacioni list", link: server("Reklamacioni_list.pdf")},
                        {name: "opšti uslovi poslovanja", link: server("Opsti_uslovi_poslovanja.docx")}].map(item => {
                        return <div key={item.name}>
                            <a href={item.link} target="_blank">
                                <i className="fa fa-paperclip" /> {item.name}
                            </a>
                        </div>
                    })}

                    <div className="mt-2">
                        <div className=""> Zakačite račun:</div>
                        <input type="file" multiple name="file" onChange={this.addFile} />
                    </div>
                </div>}


                {this.state.sent &&
                <div className="alert alert-success mt-2">
                    Email poslat.
                </div>}
            </div>

            <div className="mt-3 mb-4">
                <button onClick={() => this.props.closeDrawer("mailer")} className="btn btn-secondary mr-2">
                    Zatvorite
                </button>
                <button disabled={this.state.loading} onClick={this.email} className="btn btn-success">
                    {this.state.loading && <i className="fas fa-spinner fa-spin" />} Pošaljite
                </button>
            </div>
            {this.state.form.attachments === "true" && this.state.loading &&
                <div>
                    Slanje dokumenata, upload može da potraje...
                </div>}
        </div>
    };

    setObject = () => {

    }


    componentDidMount() {
        this.setObject();
    }
}
const mapStateToProps = state => ({
    drawer: state.drawers.mailer
});
const mapDispatchToProps = {
    closeDrawer: closeDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(MailerDrawer);
