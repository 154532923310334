import React from "react";

class Empty extends React.Component {

    state = {

    }

    render = () => {
        if(this.props.value) return this.props.value;
        return <span className="text-black-50 font-italic">prazno</span>
    };
}

export default Empty;
