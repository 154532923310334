import React from 'react';


class Overlay extends React.Component   {

    render = () => {
        let show = this.props.mobile ? "d-md-none" : ""
        let style = this.props.zIndex ? {zIndex: this.props.zIndex} : {};
        return <div className={this.props.show ? "overlay show " + show : "overlay hide " + show}
                    style={style} onClick={this.props.close} />
    };
}

export default Overlay;
