import React from "react";
import moment from "moment";
import {getOrders} from "../../../app/hooks";
import {formatPrice} from "../../../providers/price";
import {order_status} from "../../../providers/order";
import {connect} from "react-redux";
import {openDrawer} from "../../../store/actions/drawersActions";
import OrderStatusChange from "./OrderStatusChange";

class Calendar extends React.Component {

    state = {
        orders: [],
        form: {
            all: true,
            days: 7,
            offset: 0
        }
    }

    changeOffset = (add) => {
        const newState = {...this.state, form: {...this.state.form, offset: this.state.form.offset + add}};
        this.setState(newState);
        this.load(newState.form);
    }

    stats = {};
    renderColumns = () => {
        this.stats = {};
        const render = [6,5,4,3,2,1,0].map((id) => {
            this.stats[id] = {count: 0, total: 0};
            const dt = moment.utc().local().subtract((id + (this.state.form.offset*this.state.form.days)), "day").format("DD.MM.");
            if(!this.state.orders[dt]) return <td key={id} />;
            return <td key={id} className={id === 0 && this.state.form.offset === 0 ? "" : ""}>
                {this.state.orders[dt].map((order) => {
                    const status = order_status(order.status);
                    if(order.status !== 5)  {
                        this.stats[id].count++;
                        for(let i=0; i<order.products.length; i++)  {
                            const product = order.products[i];
                            let pdv = 20;
                            if(product.pivot.pdv === 0) pdv = 0;
                            if(product.pivot.pdv)   pdv = product.pivot.pdv;
                            if(product.pivot.purchase_price)    {
                                const priceAndPdv =  parseFloat(product.pivot.purchase_price) * (1 + parseInt(pdv) / 100);
                                this.stats[id].total = this.stats[id].total + (parseFloat(product.pivot.price) - priceAndPdv);
                            }
                        }
                    }
                    let className = "shadow hover p-2 pb-1 mb-2 pointer position-relative ";
                    className += order.seen ? " bg-white" : " bg-unseen";
                    return <div key={order.id} className={className} style={{border: "3px solid " + status.color}}>
                        <div onClick={() => this.props.openDrawer({name: "order", id: order.id, reload: this.load})}>
                            <div> {moment.utc(order.created_at).local().format("HH:ss")} </div>
                            <div className="text-primary font-weight-bold">{formatPrice(order.full_price)}</div>
                            <div> {order.name}  {order.lastname} </div>
                            <div>{order.address}</div>
                            <div>{order.zip} {order.city}</div>
                        </div>
                        <div style={{color: status.color}}>
                            <OrderStatusChange reload={this.load} order={order} />
                        </div>
                    </div>
                })}
            </td>
        });
        return render;
    }

    render = () => {
        const renderedColumns = this.renderColumns();
        return <div className="">
            <div className="mb-2 d-flex justify-content-between">
                <button
                    onClick={() => this.changeOffset(1)}
                    className="btn btn-white">
                    <i className="fa fa-chevron-left" /> Prethodni
                </button>
                <button
                    onClick={() => this.changeOffset(-1)}
                    className="btn btn-white">
                    Naredni <i className="fa fa-chevron-right" />
                </button>
            </div>
            <table className="table-sm table table-bordered">
                <thead>
                <tr>
                    {[6,5,4,3,2,1,0].map((id) => {
                        return <td key={id} className="bg-white shadow" style={{width: "12%"}}>
                            <div>
                                {moment().subtract((id + (this.state.form.offset*this.state.form.days)), "days").format("ddd DD.MM.")}
                                {id === 0 && this.state.form.offset === 0 &&
                                <span className="ml-2 font-weight-bold">DANAS</span>}
                            </div>
                            <div>Broj porudzbina: {this.stats[id].count}</div>
                            <div>Zarada: {this.stats[id].total}</div>

                        </td>
                    })}
                </tr>
                </thead>
                <tbody>
                <tr>
                    {renderedColumns}
                </tr>
                </tbody>

            </table>
        </div>
    };

    load = (form) => {
        if(!form) form = this.state.form;
        getOrders(form).then((response) => {
            let holder = {};
            for(let i=0; i<response.data.length; i++)   {
                const item = response.data[i];
                const dt = moment.utc(item.created_at).local().format("DD.MM.");
                if(!holder[dt]) holder[dt] = [];
                holder[dt].push(item);
            }
            this.setState({...this.state, orders: holder})
        })
    }
    componentDidMount() {
       this.load();
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
