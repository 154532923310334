
import moment from "moment";

moment.updateLocale("rs", {
    months : [
        "Januar", "Februar", "Mart", "April", "Maj", "Jun", "Jul",
        "Avgust", "Septembar", "Oktobar", "Novembar", "Decembar"
    ],
    weekdays: ["Nedelja", "Ponedeljak", "Utorak", "Sreda", "Četvrtak", "Petak", "Subota"],
    weekdaysShort: ["Ned", "Pon", "Uto", "Sre", "Čet", "Pet", "Sub"],
});