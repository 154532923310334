import axios from "axios";
import {server, updater} from "./helpers";


export async function postAdminLogin(form) {
    return await axios.post(server("admin/login"), form);
}

export async function getProducts(form) {
    return await axios.get(server("admin/products"), {params: form});
}
export async function patchProducts(form) {
    return await axios.patch(server("products"), form);
}
export async function putProduct(form) {
    return await axios.put(server("products"), form);
}
export async function deleteProduct(form) {
    return await axios.delete(server("products"), {data: form});
}
export async function patchProductsActivationAll(form) {
    return await axios.patch(server("products/activation/all"), form);
}
export async function putProductImages(form) {
    return await axios.put(server("product/images"), form);
}
export async function deleteProductImage(form) {
    return await axios.delete(server("product/image"), {data: form});
}

export async function getCategories(form) {
    return await axios.get(server("categories"), {params: form});
}
export async function putCategories(form) {
    return await axios.put(server("categories"), form);
}
export async function deleteCategories(form) {
    return await axios.delete(server("categories"), {data: form});
}
export async function patchCategoriesOrder(form) {
    return await axios.patch(server("categories/order"), form);
}
export async function getMappedCategories(form) {
    return await axios.get(server("categories/mapped"), {params: form});
}
export async function patchMappedCategories(form) {
    return await axios.patch(server("categories/mapped"), form);
}
export async function getSuppliersCategories(form) {
    return await axios.get(server("categories/suppliers"), {params: form});
}

export async function getSuppliers(form) {
    return await axios.get(server("suppliers"), {params: form});
}
export async function putSupplier(form) {
    return await axios.put(server("suppliers"), form);
}

export async function getOrders(form) {
    return await axios.get(server("orders"), {params: form});
}
export async function getUnseenOrdersCount(form) {
    return await axios.get(server("orders/unseen/count"), {params: form});
}
export async function patchOrder(form) {
    return await axios.patch(server("order"), form);
}
export async function putOrderHistory(form) {
    return await axios.put(server("order/history"), form);
}

export async function getReports(form) {
    return await axios.get(server("reports"), {params: form});
}

export async function patchPath(form) {
    return await axios.patch(server("path"), form);
}

export async function patchEponudaModel(form) {
    return await axios.patch(server("eponuda/model"), form);
}
export async function patchEponudaConnect(form) {
    return await axios.patch(server("eponuda/connect"), form);
}
export async function putEponudaUrl(form) {
    return await axios.put(server("eponuda/url"), form);
}
export async function getEponudaLogs(form) {
    return await axios.get(server("eponuda/logs"), {params: form});
}

export async function postRunUpdate(form) {
    return await axios.post(updater("update"), form);
}

export async function getFiles(form) {
    return await axios.get(server("files"), {params: form});
}
export async function deleteFiles(form) {
    return await axios.delete(updater("file"), {data: form});
}
export async function getBanners(form) {
    return await axios.get(server("interface/banners"), {params: form});
}
export async function deleteBanners(form) {
    return await axios.delete(server("interface/banners"), {params: form});
}
export async function putBanner(form) {
    return await axios.put(server("interface/banner"), form);
}
export async function patchBannersOrder(form) {
    return await axios.patch(server("interface/banners/order"), form);
}
export async function getBlocks(form) {
    return await axios.get(server("interface/blocks"), {params: form});
}
export async function deleteBlock(form) {
    return await axios.delete(server("interface/block"), {data: form});
}
export async function patchBlocksOrder(form) {
    return await axios.patch(server("interface/blocks/order"), form);
}
export async function putBlockBanners(form) {
    return await axios.put(server("interface/block/banners"), form);
}
export async function putBlockCarousel(form) {
    return await axios.put(server("interface/block/carousel"), form);
}
export async function putCarouselProducts(form) {
    return await axios.put(server("interface/block/carousel/products"), form);
}
export async function deleteCarouselProduct(form) {
    return await axios.delete(server("interface/block/carousel/product"), {data: form});
}
export async function patchCarouselProductsOrder(form) {
    return await axios.patch(server("interface/block/carousel/products/order"), form);
}
export async function postMail(form) {
    return await axios.post(server("mailer"), form);
}

export async function getUnseenChatCount(form) {
    return await axios.get(server("chat/unseen/count"), {params: form});
}
export async function getChat(form) {
    return await axios.get(server("chat"), {params: form});
}
export async function postChatResponse(form) {
    return await axios.post(server("chat/response"), form);
}

