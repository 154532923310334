import React from "react";
import {getMappedCategories} from "../../../app/hooks";
import MappedCategoryItem from "../dashboard/MappedCategoryItem";
import Table from "../../framework/table/Table";


class MappedCategories extends React.Component {

    state = {
        reload: false,
    }

    renderInfo = (item) => {
        return <MappedCategoryItem item={item}
             reload={() => this.setState({...this.state, reload: !this.state.reload})} />
    }

    render = () => {
        return <div className="container">
            <Table
                columns={[
                    {field: "info", name: "Informacije", render: this.renderInfo},
                ]}
                filters={[{name: "supplier", type: "supplier"}]}
                form={{withSupplier: true, withCategory: true}}
                hook={getMappedCategories} reload={this.state.reload} />
        </div>
    };
}
export default MappedCategories;
