import React from 'react';
import {connect} from "react-redux";
import {deleteProductImage, patchPath} from "../../../app/hooks";
import {image} from "../../../app/helpers";


class ProductGallery extends React.Component {

    changeImage = (id) => {
        patchPath({path: `products.${this.props.product.id}.image_id`, value: id}).then((response) => {
            this.props.reload();
        });
    }

    deleteImage = (id) => {
        var r = window.confirm("Želite da obrišete sliku?");
        if (r !== true) {
            return false;
        }
        deleteProductImage({id: id}).then(response => {
            this.props.reload();
        });
    }

    render() {
        const product = this.props.product;
        return (
            product.images.map((item) => {
                const selected = product.image_id === item.id ? "selected" : "";
                return <div key={item.id} className="col-md-3 mb-2">
                    <div className={`bg-white storage_file ${selected} shadow mr-1 ml-1`}>
                        {selected && <i className="fas fa-check-square" />}
                        <button onClick={() => this.deleteImage(item.pivot.id)}
                            className="btn btn-danger btn-sm">
                            <i className="fa fa-trash" />
                        </button>
                        <div onClick={() => this.changeImage(item.id)}>
                            <img src={image(item.path)} alt={item.name} className="img-fluid" />
                        </div>
                    </div>
                </div>
            })
        );
    }
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(ProductGallery);
