import React from "react";
import {connect} from "react-redux";
import {deleteCarouselProduct} from "../../../app/hooks";
import Input from "../../framework/form/Input";
import {image} from "../../../app/helpers";
import {openDrawer} from "../../../store/actions/drawersActions";
import {formatPrice} from "../../../providers/price";
import DateTimePicker from "../../framework/form/DateTimePicker";
import {isAfterNow, isBeforeNow} from "../../../providers/time";


class CarouselProduct extends React.Component {

    deleteProduct = () => {
        if (!window.confirm("Želite da obrišete proizvod iz carousela?")) {
            return false;
        }
        deleteCarouselProduct({id: this.props.item.id}).then((response) => {
            this.props.reload();
        });
    }

    render = () => {
        const cp = this.props.item;
        if(!cp.product) {
            return <div key={cp.id} className="p-2 bg-light mb-2">
                <h5>PROIZVOD JE OBRISAN!</h5>
                <button onClick={this.deleteProduct} className="btn btn-danger btn-sm">
                    Obrišite
                </button>
            </div>
        }
        //let border = "3px solid green";
        if(cp.product.admin_price)      {
            let set = false;
            if(cp.product.countdown_start)        {
                if(isAfterNow(cp.product.countdown_start)) {
                    //border = "3px solid orange";
                    console.log("orange")
                    set = true;
                }
            }
            if(!set && cp.product.countdown_end)        {
                if(isBeforeNow(cp.product.countdown_end)) {
                    //border = "3px solid red";
                }
            }
        }
        return <div key={cp.id} className="p-2 bg-light d-flex align-items-center mb-2">
            <div style={{width: 120}}>
                <img src={image(cp.product.image_path)} alt="" className="img-fluid" />
            </div>
            <div className="flex-grow-1 ml-3">
                <a href="/" className="font-weight-bold mb-2 d-inline-block" onClick={(e) =>
                    this.props.openDrawer({e: e, name: "product", id: cp.product.id})}>{cp.product.name}
                </a>
                <div className="row">
                    <div className="col-md-3">
                        <div> kupovna cena: {formatPrice(cp.product.purchase_price)} </div>
                        <div> izračunata cena: {formatPrice(cp.product.calculated_price)} </div>
                        <div> sistem cena: {formatPrice(cp.product.recommended_price)} </div>
                    </div>
                    <div className="col-md-3">
                        <div className="font-weight-bold">POSTAVITE CENU:</div>
                        <Input
                            placeholder="Cena"
                            submit={true}
                            price={true}
                            nullable={true}
                            name="admin_price"
                            path={`products.${cp.product.id}.admin_price`}
                            value={cp.product.admin_price ? cp.product.admin_price : ""} />

                        <div className="mt-2">CENA PRE SNIŽENJA:</div>
                        <Input
                            submit={true}
                            price={true}
                            nullable={true}
                            name="admin_discount_price"
                            path={`products.${cp.product.id}.admin_discount_price`}
                            value={cp.product.admin_discount_price ? cp.product.admin_discount_price : ""} />
                    </div>
                    <div className="col-md-3">
                        <div className="font-weight-bold">SNIŽENJE POČINJE:</div>
                        <DateTimePicker
                            path={`products.${cp.product.id}.countdown_start`}
                            value={cp.product.countdown_start}
                            name={`dateTimePicker_${cp.id}_start`} />

                        <div className="font-weight-bold mt-3">SNIŽENJE SE ZAVRŠAVA:</div>
                        <DateTimePicker
                            path={`products.${cp.product.id}.countdown_end`}
                            value={cp.product.countdown_end}
                            name={`dateTimePicker_${cp.id}_end`} />
                    </div>
                    <div className="col-md-3 text-right">
                        <div className="font-weight-bold mb-3">
                            {this.props.handler}
                        </div>
                        <button onClick={this.deleteProduct} className="btn btn-danger btn-sm">
                            Obrišite
                        </button>
                    </div>
                </div>
            </div>
        </div>
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(CarouselProduct);