
export function getSetting(name, def)       {
    let settings = localStorage.getItem("settings");
    if(!settings) return def;
    settings = JSON.parse(settings);
    if(!settings[name]) return def;
    return settings[name];
}
export function setSetting(name, value)       {
    let settings = localStorage.getItem("settings");
    if(settings)        {
        settings = JSON.parse(settings);
    } else  {
        settings = {};
    }
    settings[name] = value;
    localStorage.setItem("settings", JSON.stringify(settings));
}