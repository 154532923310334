import {SET_LIVE, DISCONNECT_LIVE} from "../actions/liveActions";

const liveReducer = function(state = {}, {type, payload})      {
    switch(type)        {
        case SET_LIVE:
            let newState = {...state};
            if(!payload["uid"] || !payload["sid"]) return state;
            if(!newState[payload["uid"]])      {
                newState[payload["uid"]] = {sessions: [payload["sid"]], data: []};
            } else  {
                if(newState[payload["uid"]].data[0].id === payload.data?.id)     {
                    return state;
                }
            }
            if(!newState[payload["uid"]].sessions.includes(payload["sid"])) {
                newState[payload["uid"]].sessions.push(payload["sid"]);
            }
            newState[payload["uid"]].data.unshift(payload["data"]);
            newState[payload["uid"]].data = newState[payload["uid"]].data.slice(0, 20);
            return newState;
        case DISCONNECT_LIVE:
            let holder = [];
            for(let key in state)       {
                if(state[key].sessions.includes(payload.sid))       {
                    if(state[key].sessions.length === 1)        {

                    } else  {
                        const sessions = state[key].sessions.filter(item => item !== payload.sid);
                        holder[key] = {sessions: sessions, data: state[key].data};
                    }
                } else  {
                    holder[key] = state[key];
                }
            }

            return holder;
        default:
            return state;
    }
}
export default liveReducer;