import React from "react";
import Table from "../../framework/table/Table";
import {getOrders, patchPath} from "../../../app/hooks";
import {formatPrice, processPrice, renderPrice} from "../../../providers/price";
import {formatDateTime, formatTime, fromNow} from "../../../providers/time";
import {image} from "../../../app/helpers";
import {order_status, order_statuses, payment_method} from "../../../providers/order";
import Calendar from "./Calendar";
import {openDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";
import {getSetting, setSetting} from "../../../providers/settings";
import OrderStatusChange from "./OrderStatusChange";


class Orders extends React.Component {

    state = {
        reload: false,
        calendar: true,
        status: []
    }

    toggleCalendar = () => {
        setSetting("calendar", !this.state.calendar);
        this.setState({...this.state, calendar: !this.state.calendar});
    }

    reload = () => {
        this.setState({...this.state, reload: !this.state.reload});
    }

    pin = (id, value) =>      {
        patchPath({path: `orders.${id}.pinned`, value: value}).then((response) => {
            this.reload();
        })
    }
    renderState = (order) => {
        return <div>
            {order.pinned
                ? <button onClick={() => this.pin(order.id, null)}
                    title="Zakačene porudžbine su uvek na vrhu, bez obzira na datum." className="btn btn-success btn-sm mb-2">
                <i className="fas fa-thumbtack" /> Zakačeno
            </button>
                : <button onClick={() => this.pin(order.id, 1)}
                    title="Zakačite porudžbinu da bude na vrhu." className="btn btn-light btn-sm mb-2">
                    <i className="fas fa-thumbtack" /> Zakačite
                </button>}
            <div className="font-weight-bold text-primary"> {formatPrice(order.full_price)} </div>
            <div title="Šifra porudžbine" className="font-weight-bold"> #{order.code} </div>
            <div className=""> {formatDateTime(order.created_at)} </div>
            <div className="mb-3"> {fromNow(order.created_at)} </div>
            <div className="">
                <button onClick={() => this.props.openDrawer({name: "order", id: order.id, reload: this.reload})}
                    className="btn btn-primary">Detaljnije</button>
            </div>
        </div>
    }
    renderInfo = (order) => {
        return <div>
            {order.firm_name &&
            <div className="bg-light p-2 mb-2">
                <div className=""> {order.firm_name} </div>
                <div className=""> {order.firm_pib} </div>
            </div>}
            <div className="mb-2"> {order.name} {order.lastname} </div>
            <div title="Šifra porudžbine" className=""> <i className="fa fa-envelope" /> {order.email} </div>
            <div className=""> <i className="fa fa-phone mb-3" /> {order.phone} </div>
            <div className=""> <i className="font-weight-bold" /> {order.address} </div>
            <div className="mb-2"> <i className="" /> {order.zip} {order.city} </div>
            <div className="">
                {order.payment_method === 3 &&
                <div className={order.checkout_error ? "alert p-2 alert-danger" : "alert p-2 alert-success"}>
                    <strong><i className="far fa-credit-card" /> {payment_method(order.payment_method)}</strong>
                </div>}
                {order.payment_method === 2 &&
                <div className="alert alert-info p-2">
                    <strong><i className="fas fa-file-invoice" /> {payment_method(order.payment_method)}</strong>
                </div>}
                {order.payment_method === 1 &&
                <div className="alert alert-dark p-2">
                    <strong><i className="fa fa-home" /> {payment_method(order.payment_method)}</strong>
                </div>}

            </div>
            {order.message &&
            <div title={order.message} className="">
                <i className="fa fa-commenting mt-2" /> dodatna poruka
            </div>}
        </div>
    }

    renderProducts = (order) => {
        return <div>
            {order.products.map((item) => {
                return <div key={item.id} className="bg-light p-1 mb-2">
                    <div className="d-flex align-items-center">
                        <div className="mr-3" style={{width: 50}}>
                            <img src={image(item.image_path)} alt={item.name} className="img-fluid"/>
                        </div>
                        <div>
                            <div className="mb-2 font-weight-bold">
                                <a href="/" onClick={(e) => this.props.openDrawer({e: e, name: "product", id: item.id})}>
                                    {item.name}
                                </a>
                           </div>
                            <div className="d-flex">
                                <div className="mr-3 bg-white">
                                    {renderPrice(item)}
                                </div>
                                <div className="flex-grow-1">
                                    <div className="mb-1">
                                        <a href="/" className="inherit" onClick={(e) => this.props.openDrawer({e: e, name: "supplier", id: item.supplier?.id})}>
                                            Dobavljač: {item.supplier?.name}
                                        </a>
                                    </div>
                                    <div title="Cena od dobavljaca (u fajlu, sistem azurira).">
                                        Cena dobavljača (vreme kupovine): {formatPrice(item.pivot.purchase_price)}
                                    </div>
                                    <div title="Cena od dobavljaca (u fajlu, sistem azurira).">
                                        Trenutna cena: {formatPrice(processPrice(item))}
                                    </div>
                                    <div className="font-weight-bold">
                                        Poručena cena: {formatPrice(item.pivot.price)} <br />
                                        <div className="text-success">
                                            Količina: {item.pivot.qty}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            })}
        </div>
    }

    renderHistory = (order) => {
        return <div>
            {order.history.map((item) => {
                const status = order_status(item.status);
                return <div key={item.id}>
                    <div>
                        <span className="text-black-50 font-italic"> {formatTime(item.created_at)} </span>
                        <span className="font-weight-bold ml-1" style={{color: status.color}}> {status.title} </span>
                        {item.message &&
                        <i title={item.message} className="fa fa-commenting text-primary" />}
                        {item.admin_id &&
                        <span className="ml-1 text-black-50 font-italic"> {item.admin_name} </span>}
                    </div>
                </div>
            })}
            <div className="mt-3 mb-2">
                <button onClick={() => this.props.openDrawer({name: "order", id: order.id, reload: this.reload})}
                        className="btn btn-primary btn-sm">Detaljnije</button>
            </div>
            <div>
                <OrderStatusChange reload={this.reload} order={order} />
            </div>
        </div>
    }

    render = () => {
        return <div className="">
            <div className="mb-3">
                <button onClick={this.toggleCalendar}
                    className="btn btn-info pl-4 pr-4">
                    Prikaži kao {this.state.calendar ? "tabelu"  : "kalendar"}
                </button>
            </div>
            {this.state.calendar
                ? <Calendar />
                : <Table
                    columns={[
                        {field: "state", name: "Stanje", width: 180, render: this.renderState},
                        {field: "name", name: "Informacije", render: this.renderInfo},
                        {field: "products", name: "Proizvodi", render: this.renderProducts},
                        {field: "history", name: "Istorija", width:260, render: this.renderHistory}
                    ]}
                    filters={[
                        {name: "status", placeholder: "Status", type: "select", options: this.state.status},
                        ]}
                    form={{withHistory: true, status: ""}}
                    hook={getOrders}
                    reload={this.state.reload}
                />}
        </div>
    };

    componentDidMount() {
        let holder =[];
        for(let key in order_statuses())        {
            holder.push({name: order_status(key).title, value: key})
        }
        const calendar = getSetting("calendar", false);
        this.setState({...this.state, calendar: calendar, status: holder});
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Orders);
