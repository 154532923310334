import React from "react";
import {connect} from "react-redux";
import Loader from "../framework/Loader";
import {getFiles, deleteFiles} from "../../app/hooks";
import Pagination from "../framework/Pagination";
import {image, randString} from "../../app/helpers";
import {closeDrawer} from "../../store/actions/drawersActions";
import Uploader from "./Uploader";


class FilesDrawer extends React.Component {

    state = {
        loading: true,
        uploading: [],
        files: {},
        selected: [],
        form: {
            page: 1,
            search: "",
            bucket: this.props.drawer.bucket || "goproducts",
        }
    }

    toggleSelected = (item) => {
        const find = this.state.selected.find(select => select.id === item.id);
        if(!find)       {
            this.setState({...this.state, selected: [...this.state.selected, item]});
        }
        else        {
            const newSelected = this.state.selected.filter(select => select.id !== item.id);
            this.setState({...this.state, selected: newSelected});
        }
    }

    add_files = (e) => {
        if (e.target.files.length === 0) {
            alert('Niste uneli fajl.');
            return false;
        }
        for(var i=0; i<e.target.files.length; i++) {
            e.target.files[i].id = randString();
        }
        this.setState({...this.state, uploading: Object.values(e.target.files)});
        e.target.value = "";
    }

    selectItems = () => {
        if(this.props.drawer.callback) {
            this.props.drawer.callback(this.state.selected);
        }
        this.props.closeDrawer("files");
    }

    deleteItems = () => {
        var r = window.confirm("Želite da obrišete fajlove?");
        if (r !== true) {
            return false;
        }
        deleteFiles({ids: this.state.selected.map(item => item.id)}).then((response) => {
            this.setState({...this.state, selected: []});
            this.load();
        })
    }


    changeField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let newState;
        if(name === "page") {
            newState = {...this.state, form: {...this.state.form, [name]: value}};
        } else  {
            newState = {...this.state, form: {...this.state.form, [name]: value, page: 1}};
        }
        this.setState(newState);
        this.load(newState.form);
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }
        const selected = this.state.selected.map(item => item.id);
        return <div className="container-fluid mt-3 mb-5">
            <div className="d-flex align-items-center form-inline">
                <div className="mr-4">
                    <input type="file" multiple name="file" onChange={this.add_files} />
                </div>

                <div>
                    <input
                        value={this.state.form.search}
                        name="search"
                        className="form-control"
                        onChange={this.changeField}
                        type="text" placeholder="Pretražite po imenu..." />
                </div>
            </div>



            {this.state.uploading.length > 0 &&
            <div className="mt-3 d-flex">
                {this.state.uploading.map((item) => {
                    return <Uploader
                        bucket={this.state.form.bucket}
                        key={item.id}
                        file={item}
                        reload={this.load} />
                })}
            </div>}

            <div className="mt-3">
                {this.state.files.data.length > 0
                    ? <div>
                        <div className="row m-0">
                            {this.state.files.data.map((item) => {
                                return <div key={item.id} className="col-6 col-md-1 p-0">
                                    <div onClick={() => this.toggleSelected(item)}
                                         className={selected.includes(item.id) ? "storage_file selected" : "storage_file"}>
                                        <i className="fas fa-check-square" />
                                        <div className="bcg_image" style={{backgroundImage: `url(${image(item.path, false, item.bucket)})`}} />
                                    </div>
                                    {item.orig_name}
                                </div>
                            })}
                        </div>

                        <Pagination
                            change={this.changeField}
                            page={this.state.form.page}
                            lastPage={this.state.files.last_page} />
                    </div>
                    : <div> Nema slika </div>}

                {this.state.selected.length > 0 &&
                <div className="storage_footer">
                    {this.props.drawer.callback &&
                    <button onClick={this.selectItems} className="btn btn-primary mr-2">
                        Selektujte
                    </button>}
                    <button onClick={this.deleteItems} className="btn btn-danger">
                        Obrišite
                    </button>
                </div>}
            </div>
        </div>
    };

    load = (form) => {
        if(!form) form = this.state.form;
        getFiles(form).then((response) => {
            this.setState({...this.state, loading: false, files: response.data});
        });
    }

    componentDidMount() {
        this.load();
    }
}
const mapStateToProps = state => ({
    drawer: state.drawers.files
});
const mapDispatchToProps = {
    closeDrawer: closeDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(FilesDrawer);
