import React from 'react';
import {connect} from "react-redux";
import {closeDrawer, openDrawer} from "../../../store/actions/drawersActions";
import Input from "../../framework/form/Input";
import Select from "react-select";
import {getSuppliers, putProduct} from "../../../app/hooks";


class ProductNew extends React.Component {

    state = {
        name: "",
        category: {},
        supplier: "",
        suppliers: []
    }

    changeCategory = (cat) => {
        if(!cat) return false;
        this.setState({...this.state, category: cat});
    }

    submit = () => {
        if(!this.state.name)        {
            alert("Unesite ime.");
            return false;
        }
        if(!this.state.category?.id)        {
            alert("Unesite kategoriju.");
            return false;
        }
        if(!this.state.supplier?.id)        {
            alert("Unesite dobavljaca.");
            return false;
        }
        const params = {name: this.state.name, category_id: this.state.category?.id, supplier_id: this.state.supplier?.id};
        putProduct(params).then((response) => {
            this.props.reload(response.data.id);
        })

    }

    render() {
        return (
            <div className="p-3 mt-5">
                <div className="bg-white shadow p-3 mb-3">
                    <Input
                        title={<div className="font-weight-bold font_2 mb-2"> Ime proizvoda </div>}
                        onChange={(e) => this.setState({...this.state, name: e.target.value})}
                        value={this.state.name} name="name"
                        maxLength={191} autoComplete="off"  />
                </div>

                <div className="bg-white shadow p-3 mb-3">
                    <div className="font-weight-bold font_2 mb-2"> Kategorija </div>
                    {this.state.category?.name || <span className="text-black-50">nije postavljeno</span>}
                    <button onClick={() => this.props.openDrawer({name: "categories",
                        callback: this.changeCategory})}
                             className="btn btn-white ml-3">
                        Promeni
                    </button>
                </div>

                <div className="bg-white shadow p-3 mb-3">
                    <div className="font-weight-bold font_2 mb-2"> Dobavljač </div>

                    <div className="col-md-5 p-0">
                        <Select
                            value={this.state.suppliers.find(option => option.id + "" === this.state.supplier)}
                            isClearable={true}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    // width: 300,
                                }),
                            }}
                            placeholder="Dobavljači"
                            onChange={(item) => this.setState({...this.state, supplier: item})}
                            options={this.state.suppliers}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id} />
                    </div>
                </div>
                <div>
                    <button onClick={this.submit} className="btn btn-success">
                        Sačuvajte proizvod
                    </button>
                </div>
            </div>
        );
    }


    componentDidMount() {
        getSuppliers({all: true}).then((response) => {
            this.setState({...this.state, suppliers: response.data});
        });
    }
}
const mapStateToProps = state => ({
    drawer: state.drawers.product
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductNew);
